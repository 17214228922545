import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createSlots as _createSlots } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mt-10",
  "data-testid": "cruaks-form"
}
const _hoisted_2 = { class: "row mb-10" }
const _hoisted_3 = {
  key: 0,
  class: "col span-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_SelectCredential = _resolveComponent("SelectCredential")!
  const _component_LabeledInput = _resolveComponent("LabeledInput")!
  const _component_LabeledSelect = _resolveComponent("LabeledSelect")!
  const _component_Import = _resolveComponent("Import")!
  const _component_Config = _resolveComponent("Config")!
  const _component_Banner = _resolveComponent("Banner")!
  const _component_ClusterMembershipEditor = _resolveComponent("ClusterMembershipEditor")!
  const _component_Accordion = _resolveComponent("Accordion")!
  const _component_Labels = _resolveComponent("Labels")!
  const _component_CruResource = _resolveComponent("CruResource")!

  return (_ctx.$fetchState.pending)
    ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
    : (_openBlock(), _createBlock(_component_CruResource, {
        key: 1,
        ref: "cruresource",
        resource: _ctx.value,
        mode: _ctx.mode,
        "can-yaml": false,
        "done-route": _ctx.doneRoute,
        errors: [..._ctx.fvUnreportedValidationErrors, ..._ctx.configUnreportedErrors],
        "validation-passed": _ctx.fvFormIsValid && ( _ctx.configIsValid || _ctx.isImport),
        onError: _cache[13] || (_cache[13] = e=>_ctx.errors=e),
        onFinish: _ctx.save
      }, _createSlots({
        default: _withCtx(() => [
          _createVNode(_component_SelectCredential, {
            value: _ctx.config.azureCredentialSecret,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.config.azureCredentialSecret) = $event)),
            "data-testid": "cruaks-select-credential",
            mode: _ctx.mode === _ctx.VIEW ? _ctx.VIEW : _ctx.CREATE,
            provider: "azure",
            "default-on-cancel": true,
            "showing-form": _ctx.hasCredential,
            class: "mt-20",
            cancel: _ctx.cancelCredential
          }, null, 8, ["value", "mode", "showing-form", "cancel"]),
          _cache[21] || (_cache[21] = _createTextVNode()),
          (_ctx.hasCredential)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["col", {'span-6': _ctx.isImport, 'span-4': !_ctx.isImport}])
                  }, [
                    _createVNode(_component_LabeledInput, {
                      value: _ctx.normanCluster.name,
                      mode: _ctx.mode,
                      "label-key": "generic.name",
                      required: "",
                      rules: _ctx.fvGetAndReportPathRules('name'),
                      "onUpdate:value": _ctx.setClusterName
                    }, null, 8, ["value", "mode", "rules", "onUpdate:value"])
                  ], 2),
                  _cache[14] || (_cache[14] = _createTextVNode()),
                  _createElementVNode("div", {
                    class: _normalizeClass(["col", {'span-6': _ctx.isImport, 'span-4': !_ctx.isImport}])
                  }, [
                    _createVNode(_component_LabeledInput, {
                      value: _ctx.normanCluster.description,
                      "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.normanCluster.description) = $event)),
                      mode: _ctx.mode,
                      "label-key": "nameNsDescription.description.label",
                      placeholder: _ctx.t('nameNsDescription.description.placeholder')
                    }, null, 8, ["value", "mode", "placeholder"])
                  ], 2),
                  _cache[15] || (_cache[15] = _createTextVNode()),
                  (!_ctx.isImport)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        _createVNode(_component_LabeledSelect, {
                          value: _ctx.config.resourceLocation,
                          "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.config.resourceLocation) = $event)),
                          "data-testid": "cruaks-resourcelocation",
                          mode: _ctx.mode,
                          options: _ctx.locationOptions,
                          "option-label": "displayName",
                          "option-key": "name",
                          "label-key": "aks.location.label",
                          reduce: opt=>opt.name,
                          loading: _ctx.loadingLocations,
                          required: "",
                          disabled: !_ctx.isNewOrUnprovisioned
                        }, null, 8, ["value", "mode", "options", "reduce", "loading", "disabled"])
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _cache[17] || (_cache[17] = _createTextVNode()),
                (_ctx.isImport)
                  ? (_openBlock(), _createBlock(_component_Import, {
                      key: 0,
                      "cluster-name": _ctx.config.clusterName,
                      "onUpdate:clusterName": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.config.clusterName) = $event)),
                      "resource-group": _ctx.config.resourceGroup,
                      "onUpdate:resourceGroup": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.config.resourceGroup) = $event)),
                      "resource-location": _ctx.config.resourceLocation,
                      "onUpdate:resourceLocation": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.config.resourceLocation) = $event)),
                      "enable-network-policy": _ctx.normanCluster.enableNetworkPolicy,
                      "onUpdate:enableNetworkPolicy": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.normanCluster.enableNetworkPolicy) = $event)),
                      "data-testid": "cruaks-import",
                      "azure-credential-secret": _ctx.config.azureCredentialSecret,
                      rules: {clusterName: _ctx.fvGetAndReportPathRules('clusterName')},
                      mode: _ctx.mode,
                      onError: _cache[7] || (_cache[7] = e=>_ctx.errors.push(e))
                    }, null, 8, ["cluster-name", "resource-group", "resource-location", "enable-network-policy", "azure-credential-secret", "rules", "mode"]))
                  : (_openBlock(), _createBlock(_component_Config, {
                      key: 1,
                      config: _ctx.config,
                      "onUpdate:config": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.config) = $event)),
                      "config-unreported-errors": _ctx.configUnreportedErrors,
                      "onUpdate:configUnreportedErrors": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.configUnreportedErrors) = $event)),
                      "config-is-valid": _ctx.configIsValid,
                      "onUpdate:configIsValid": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.configIsValid) = $event)),
                      "enable-network-policy": _ctx.normanCluster.enableNetworkPolicy,
                      "onUpdate:enableNetworkPolicy": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.normanCluster.enableNetworkPolicy) = $event)),
                      value: _ctx.value,
                      mode: _ctx.mode,
                      "original-version": _ctx.originalVersion,
                      "is-new-or-unprovisioned": _ctx.isNewOrUnprovisioned
                    }, null, 8, ["config", "config-unreported-errors", "config-is-valid", "enable-network-policy", "value", "mode", "original-version", "is-new-or-unprovisioned"])),
                _cache[18] || (_cache[18] = _createTextVNode()),
                _createVNode(_component_Accordion, {
                  class: "mb-20",
                  "title-key": "members.memberRoles"
                }, {
                  default: _withCtx(() => [
                    (_ctx.isEdit)
                      ? (_openBlock(), _createBlock(_component_Banner, {
                          key: 0,
                          color: "info"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t('cluster.memberRoles.removeMessage')), 1)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    _cache[16] || (_cache[16] = _createTextVNode()),
                    (_ctx.canManageMembers)
                      ? (_openBlock(), _createBlock(_component_ClusterMembershipEditor, {
                          key: 1,
                          mode: _ctx.mode,
                          "parent-id": _ctx.normanCluster.id ? _ctx.normanCluster.id : null,
                          onMembershipUpdate: _ctx.onMembershipUpdate
                        }, null, 8, ["mode", "parent-id", "onMembershipUpdate"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }),
                _cache[19] || (_cache[19] = _createTextVNode()),
                _createVNode(_component_Accordion, {
                  class: "mb-20",
                  "title-key": "aks.accordions.labels"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Labels, {
                      value: _ctx.normanCluster,
                      "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.normanCluster) = $event)),
                      mode: _ctx.mode
                    }, null, 8, ["value", "mode"])
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          _cache[22] || (_cache[22] = _createTextVNode())
        ]),
        _: 2
      }, [
        (!_ctx.hasCredential)
          ? {
              name: "form-footer",
              fn: _withCtx(() => [
                _cache[20] || (_cache[20] = _createElementVNode("div", null, null, -1))
              ]),
              key: "0"
            }
          : undefined
      ]), 1032, ["resource", "mode", "done-route", "errors", "validation-passed", "onFinish"]))
}