import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "psa" }
const _hoisted_2 = { class: "mb-30" }
const _hoisted_3 = { class: "col span-2" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "col span-4" }
const _hoisted_6 = { class: "col span-4" }
const _hoisted_7 = { class: "mb-30" }
const _hoisted_8 = { class: "col span-2" }
const _hoisted_9 = { class: "col span-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_t = _resolveComponent("t")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_LabeledSelect = _resolveComponent("LabeledSelect")!
  const _component_LabeledInput = _resolveComponent("LabeledInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, [
      _createVNode(_component_t, { k: "podSecurityAdmission.description" })
    ]),
    _cache[10] || (_cache[10] = _createTextVNode()),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.psaControls, (psaControl, level, i) => {
      return (_openBlock(), _createElementBlock("div", {
        key: i,
        class: "row row--y-center mb-20"
      }, [
        _createElementVNode("span", _hoisted_3, [
          (!_ctx.labelsAlwaysActive)
            ? (_openBlock(), _createBlock(_component_Checkbox, {
                key: 0,
                value: psaControl.active,
                "onUpdate:value": [
                  ($event: any) => ((psaControl.active) = $event),
                  _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateLabels()))
                ],
                "data-testid": _ctx.componentTestid + '--psaControl-' + i + '-active',
                label: level,
                "label-key": `podSecurityAdmission.labels.${ level }`,
                disabled: _ctx.isView
              }, null, 8, ["value", "onUpdate:value", "data-testid", "label", "label-key", "disabled"]))
            : (_openBlock(), _createElementBlock("p", _hoisted_4, [
                _createVNode(_component_t, {
                  id: `psa-label-for-level-${ level }`,
                  k: `podSecurityAdmission.labels.${level}`
                }, null, 8, ["id", "k"])
              ]))
        ]),
        _cache[5] || (_cache[5] = _createTextVNode()),
        _createElementVNode("span", _hoisted_5, [
          _createVNode(_component_LabeledSelect, {
            value: psaControl.level,
            "onUpdate:value": [
              ($event: any) => ((psaControl.level) = $event),
              _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateLabels()))
            ],
            "aria-labelledby": `psa-label-for-level-${ level }`,
            "data-testid": _ctx.componentTestid + '--psaControl-' + i + '-level',
            disabled: _ctx.isPsaControlDisabled(psaControl.active),
            options: _ctx.options,
            mode: _ctx.mode
          }, null, 8, ["value", "onUpdate:value", "aria-labelledby", "data-testid", "disabled", "options", "mode"])
        ]),
        _cache[6] || (_cache[6] = _createTextVNode()),
        _createElementVNode("span", _hoisted_6, [
          _createVNode(_component_LabeledInput, {
            value: psaControl.version,
            "onUpdate:value": [
              ($event: any) => ((psaControl.version) = $event),
              _cache[2] || (_cache[2] = ($event: any) => (_ctx.updateLabels()))
            ],
            "data-testid": _ctx.componentTestid + '--psaControl-' + i + '-version',
            disabled: _ctx.isPsaControlDisabled(psaControl.active),
            options: _ctx.options,
            placeholder: _ctx.t('podSecurityAdmission.version.placeholder', { psaControl: _ctx.mode }),
            mode: _ctx.mode,
            "aria-label": `${_ctx.t(`podSecurityAdmission.labels.${level}`)} - ${_ctx.t('podSecurityAdmission.version.placeholder', { psaControl: _ctx.mode })}`
          }, null, 8, ["value", "onUpdate:value", "data-testid", "disabled", "options", "placeholder", "mode", "aria-label"])
        ])
      ]))
    }), 128)),
    _cache[11] || (_cache[11] = _createTextVNode()),
    (_ctx.hasExemptions)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _renderSlot(_ctx.$slots, "title", {}, () => [
            _createElementVNode("h3", null, [
              _createVNode(_component_t, { k: "podSecurityAdmission.exemptions.title" })
            ])
          ]),
          _cache[8] || (_cache[8] = _createTextVNode()),
          _createElementVNode("p", _hoisted_7, [
            _createVNode(_component_t, { k: "podSecurityAdmission.exemptions.description" })
          ]),
          _cache[9] || (_cache[9] = _createTextVNode()),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.psaExemptionsControls, (psaExemptionsControl, dimension, i) => {
            return (_openBlock(), _createElementBlock("div", {
              key: i,
              class: "row row--y-center mb-20"
            }, [
              _createElementVNode("span", _hoisted_8, [
                _createVNode(_component_Checkbox, {
                  value: psaExemptionsControl.active,
                  "onUpdate:value": [
                    ($event: any) => ((psaExemptionsControl.active) = $event),
                    _cache[3] || (_cache[3] = ($event: any) => (_ctx.updateExemptions()))
                  ],
                  "data-testid": _ctx.componentTestid + '--psaExemptionsControl-' + i + '-active',
                  label: dimension,
                  "label-key": `podSecurityAdmission.labels.${ dimension }`,
                  disabled: _ctx.isView
                }, null, 8, ["value", "onUpdate:value", "data-testid", "label", "label-key", "disabled"])
              ]),
              _cache[7] || (_cache[7] = _createTextVNode()),
              _createElementVNode("span", _hoisted_9, [
                _createVNode(_component_LabeledInput, {
                  value: psaExemptionsControl.value,
                  "onUpdate:value": [
                    ($event: any) => ((psaExemptionsControl.value) = $event),
                    _cache[4] || (_cache[4] = ($event: any) => (_ctx.updateExemptions()))
                  ],
                  "data-testid": _ctx.componentTestid + '--psaExemptionsControl-' + i + '-value',
                  disabled: (_ctx.isView || !psaExemptionsControl.active),
                  options: _ctx.options,
                  placeholder: _ctx.t('podSecurityAdmission.exemptions.placeholder', { psaExemptionsControl: dimension }),
                  mode: _ctx.mode,
                  "aria-label": `${_ctx.t(`podSecurityAdmission.labels.${ dimension }`)} - ${_ctx.t('podSecurityAdmission.exemptions.placeholder', { psaExemptionsControl: dimension })}`
                }, null, 8, ["value", "onUpdate:value", "data-testid", "disabled", "options", "placeholder", "mode", "aria-label"])
              ])
            ]))
          }), 128))
        ], 64))
      : _createCommentVNode("", true)
  ]))
}