// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.key-value{width:100%}.key-value .file-selector.role-link{text-transform:none;padding:0}.key-value .kv-container{display:grid;align-items:center;-moz-column-gap:20px;column-gap:20px}.key-value .kv-container label{margin-bottom:0}.key-value .kv-container .kv-item{width:100%;margin:10px 0 10px 0}.key-value .kv-container .kv-item.extra,.key-value .kv-container .kv-item.key{align-self:flex-start}.key-value .kv-container .kv-item.value .value-container.upload-button{position:relative;display:flex;justify-content:right;align-items:center}.key-value .kv-container .kv-item.value .value-container .file-selector{position:absolute;margin-right:5px}.key-value .kv-container .kv-item.value textarea{padding:10px 10px 10px 10px}.key-value .kv-container .kv-item .text-monospace:not(.conceal){font-family:monospace,monospace}.key-value .row,.key-value .rowgroup{display:grid;grid-column-start:1;grid-column-end:span end;grid-template-columns:subgrid}.key-value .row:before{display:none}.key-value .remove{text-align:center}.key-value .remove BUTTON{padding:0}.key-value .title{margin-bottom:10px}.key-value .title .read-from-file{float:right}.key-value input{height:40px;line-height:1}.key-value .footer .protip{float:right;padding:5px 0}.key-value .download{text-align:right}.key-value .copy-value{padding:0 0 0 10px}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
