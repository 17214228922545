import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "buttons" }
const _hoisted_3 = { class: "left" }
const _hoisted_4 = { class: "right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Banner = _resolveComponent("Banner")!
  const _component_t = _resolveComponent("t")!
  const _component_AsyncButton = _resolveComponent("AsyncButton")!

  return (!_ctx.isView)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _cache[5] || (_cache[5] = _createElementVNode("div", { class: "spacer-small" }, null, -1)),
        _cache[6] || (_cache[6] = _createTextVNode()),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errors, (err, idx) => {
          return (_openBlock(), _createElementBlock("div", { key: idx }, [
            _createVNode(_component_Banner, {
              color: "error",
              label: err
            }, null, 8, ["label"])
          ]))
        }), 128)),
        _cache[7] || (_cache[7] = _createTextVNode()),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _renderSlot(_ctx.$slots, "left")
          ]),
          _cache[4] || (_cache[4] = _createTextVNode()),
          _createElementVNode("div", _hoisted_4, [
            _renderSlot(_ctx.$slots, "cancel", {}, () => [
              _createElementVNode("button", {
                type: "button",
                class: "btn role-secondary",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.done && _ctx.done(...args)))
              }, [
                _createVNode(_component_t, { k: "generic.cancel" })
              ])
            ]),
            _cache[1] || (_cache[1] = _createTextVNode()),
            _renderSlot(_ctx.$slots, "middle"),
            _cache[2] || (_cache[2] = _createTextVNode()),
            _renderSlot(_ctx.$slots, "save", {}, () => [
              (!_ctx.isView)
                ? (_openBlock(), _createBlock(_component_AsyncButton, {
                    key: 0,
                    mode: _ctx.mode,
                    disabled: _ctx.disableSave,
                    onClick: _ctx.save
                  }, null, 8, ["mode", "disabled", "onClick"]))
                : _createCommentVNode("", true)
            ]),
            _cache[3] || (_cache[3] = _createTextVNode()),
            _renderSlot(_ctx.$slots, "right")
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}