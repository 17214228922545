import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, normalizeProps as _normalizeProps, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

import {
  RcDropdown,
  RcDropdownItem,
  RcDropdownSeparator,
  RcDropdownTrigger
} from '@components/RcDropdown';
import { RcDropdownMenuComponentProps, DropdownOption } from './types';
import IconOrSvg from '@shell/components/IconOrSvg';

// eslint-disable-next-line vue/no-setup-props-destructure

export default /*@__PURE__*/_defineComponent({
  __name: 'RcDropdownMenu',
  props: {
    options: {},
    buttonRole: { default: 'primary' },
    buttonSize: { default: '' },
    buttonAriaLabel: {},
    dropdownAriaLabel: {},
    dataTestid: {}
  },
  emits: ['update:open', 'select'],
  setup(__props: any, { emit: __emit }) {



const emit = __emit;

const hasOptions = (options: DropdownOption[]) => {
  return options.length !== undefined ? options.length : Object.keys(options).length > 0;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(RcDropdown), {
    "aria-label": _ctx.dropdownAriaLabel,
    "onUpdate:open": _cache[0] || (_cache[0] = (e) => emit('update:open', e))
  }, {
    dropdownCollection: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (a) => {
        return (_openBlock(), _createElementBlock(_Fragment, {
          key: a.label
        }, [
          (!a.divider)
            ? (_openBlock(), _createBlock(_unref(RcDropdownItem), {
                key: 0,
                onClick: (e) => emit('select', e, a)
              }, {
                before: _withCtx(() => [
                  (a.icon || a.svg)
                    ? (_openBlock(), _createBlock(_unref(IconOrSvg), {
                        key: 0,
                        icon: a.icon,
                        src: a.svg,
                        class: "icon",
                        color: "header"
                      }, null, 8, ["icon", "src"]))
                    : _createCommentVNode("", true)
                ]),
                default: _withCtx(() => [
                  _createTextVNode(" " + _toDisplayString(a.label), 1)
                ]),
                _: 2
              }, 1032, ["onClick"]))
            : (_openBlock(), _createBlock(_unref(RcDropdownSeparator), { key: 1 }))
        ], 64))
      }), 128)),
      _cache[3] || (_cache[3] = _createTextVNode()),
      (!hasOptions(_ctx.options))
        ? (_openBlock(), _createBlock(_unref(RcDropdownItem), {
            key: 0,
            disabled: ""
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode("\n        No actions available\n      ")
            ])),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      _createVNode(_unref(RcDropdownTrigger), _normalizeProps({
        [_ctx.buttonRole || ""]: true,
        [_ctx.buttonSize || ""]: true,
        "data-testid": _ctx.dataTestid,
        "aria-label": _ctx.buttonAriaLabel
      }), {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createElementVNode("i", { class: "icon icon-actions" }, null, -1)
        ])),
        _: 1
      }, 16, ["data-testid", "aria-label"]),
      _cache[4] || (_cache[4] = _createTextVNode())
    ]),
    _: 1
  }, 8, ["aria-label"]))
}
}

})