import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, Fragment as _Fragment, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, renderList as _renderList } from "vue"

const _hoisted_1 = { class: "pool" }
const _hoisted_2 = { class: "row mb-10" }
const _hoisted_3 = {
  key: 0,
  class: "col span-6"
}
const _hoisted_4 = {
  key: 1,
  class: "col span-3"
}
const _hoisted_5 = {
  key: 2,
  class: "col span-6"
}
const _hoisted_6 = { class: "row mb-10" }
const _hoisted_7 = { class: "col span-3" }
const _hoisted_8 = { class: "col span-3" }
const _hoisted_9 = { class: "col span-3" }
const _hoisted_10 = { class: "col span-2" }
const _hoisted_11 = { class: "text-label" }
const _hoisted_12 = { class: "row mb-10" }
const _hoisted_13 = { class: "col span-3" }
const _hoisted_14 = { class: "col span-3" }
const _hoisted_15 = {
  style: {'display': 'flex', 'align-items':'center'},
  class: "row mb-10"
}
const _hoisted_16 = { class: "col span-3" }
const _hoisted_17 = { class: "col span-3" }
const _hoisted_18 = { class: "col span-3" }
const _hoisted_19 = { class: "row mb-10" }
const _hoisted_20 = { class: "col span-3" }
const _hoisted_21 = { class: "col span-3" }
const _hoisted_22 = { class: "col span-3" }
const _hoisted_23 = { class: "row mb-10" }
const _hoisted_24 = { class: "col span-12" }
const _hoisted_25 = { class: "text-label" }
const _hoisted_26 = { class: "icon icon-info" }
const _hoisted_27 = {
  key: 0,
  class: "taints"
}
const _hoisted_28 = { class: "text-label" }
const _hoisted_29 = { class: "text-label" }
const _hoisted_30 = { class: "text-label" }
const _hoisted_31 = { key: 1 }
const _hoisted_32 = { class: "row" }
const _hoisted_33 = { class: "col span-12" }
const _hoisted_34 = { class: "text-label" }
const _hoisted_35 = { class: "icon icon-info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_LabeledInput = _resolveComponent("LabeledInput")!
  const _component_Banner = _resolveComponent("Banner")!
  const _component_LabeledSelect = _resolveComponent("LabeledSelect")!
  const _component_RadioGroup = _resolveComponent("RadioGroup")!
  const _component_UnitInput = _resolveComponent("UnitInput")!
  const _component_Taint = _resolveComponent("Taint")!
  const _component_KeyValue = _resolveComponent("KeyValue")!
  const _directive_clean_tooltip = _resolveDirective("clean-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.upgradeAvailable && !_ctx.clusterWillUpgrade)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_Checkbox, {
              value: _ctx.willUpgrade,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.willUpgrade) = $event)),
              mode: _ctx.mode,
              label: _ctx.upgradeLabel,
              "data-testid": "aks-pool-upgrade-checkbox"
            }, null, 8, ["value", "mode", "label"])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_LabeledInput, {
              value: _ctx.pool.orchestratorVersion,
              "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pool.orchestratorVersion) = $event)),
              mode: _ctx.mode,
              "label-key": "aks.nodePools.orchestratorVersion.label",
              disabled: "",
              "data-testid": "aks-pool-version-display"
            }, null, 8, ["value", "mode"])
          ])),
      _cache[17] || (_cache[17] = _createTextVNode()),
      (_ctx.clusterWillUpgrade && _ctx.upgradeAvailable)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_Banner, {
              class: "mt-0",
              color: "info",
              "label-key": "aks.nodePools.orchestratorVersion.warning",
              "data-testid": "aks-pool-upgrade-banner"
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    _cache[37] || (_cache[37] = _createTextVNode()),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_LabeledInput, {
          value: _ctx.pool.name,
          "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.pool.name) = $event)),
          mode: _ctx.mode,
          "label-key": "generic.name",
          required: "",
          disabled: !_ctx.pool._isNewOrUnprovisioned,
          rules: _ctx.validationRules.name
        }, null, 8, ["value", "mode", "disabled", "rules"])
      ]),
      _cache[18] || (_cache[18] = _createTextVNode()),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_LabeledSelect, {
          value: _ctx.pool.vmSize,
          "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.pool.vmSize) = $event)),
          options: _ctx.vmSizeOptions,
          "label-key": "aks.nodePools.vmSize.label",
          loading: _ctx.loadingVmSizes,
          mode: _ctx.mode,
          disabled: !_ctx.pool._isNewOrUnprovisioned,
          rules: [()=>_ctx.pool._validation && _ctx.pool._validation._validSize === false ? _ctx.t('aks.errors.vmSizes.available') : undefined]
        }, null, 8, ["value", "options", "loading", "mode", "disabled", "rules"])
      ]),
      _cache[19] || (_cache[19] = _createTextVNode()),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_LabeledSelect, {
          value: _ctx.pool.availabilityZones,
          "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.pool.availabilityZones) = $event)),
          options: _ctx.availabilityZoneOptions,
          "label-key": "aks.nodePools.availabilityZones.label",
          mode: _ctx.mode,
          multiple: true,
          taggable: true,
          disabled: !_ctx.pool._isNewOrUnprovisioned || (!_ctx.canUseAvailabilityZones && !(_ctx.pool.availabilityZones && _ctx.pool.availabilityZones.length)),
          "require-dirty": false,
          rules: _ctx.validationRules.az
        }, null, 8, ["value", "options", "mode", "disabled", "rules"])
      ]),
      _cache[20] || (_cache[20] = _createTextVNode()),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_RadioGroup, {
          value: _ctx.pool.mode,
          "onUpdate:value": [
            _cache[5] || (_cache[5] = ($event: any) => ((_ctx.pool.mode) = $event)),
            _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('validationChanged')))
          ],
          mode: _ctx.mode,
          options: _ctx.modeOptions,
          name: `${_ctx.pool._id}-mode`,
          row: true,
          "label-key": "generic.mode"
        }, {
          label: _withCtx(() => [
            _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.t('aks.nodePools.mode.label')), 1)
          ]),
          _: 1
        }, 8, ["value", "mode", "options", "name"])
      ])
    ]),
    _cache[38] || (_cache[38] = _createTextVNode()),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_LabeledSelect, {
          value: _ctx.pool.osDiskType,
          "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.pool.osDiskType) = $event)),
          options: _ctx.osDiskTypeOptions,
          "label-key": "aks.nodePools.osDiskType.label",
          mode: _ctx.mode,
          disabled: !_ctx.pool._isNewOrUnprovisioned
        }, null, 8, ["value", "options", "mode", "disabled"])
      ]),
      _cache[21] || (_cache[21] = _createTextVNode()),
      _createElementVNode("div", _hoisted_14, [
        _createVNode(_component_UnitInput, {
          value: _ctx.pool.osDiskSizeGB,
          "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.pool.osDiskSizeGB) = $event)),
          "label-key": "aks.nodePools.osDiskSize.label",
          mode: _ctx.mode,
          suffix: "GB",
          disabled: !_ctx.pool._isNewOrUnprovisioned,
          type: "number"
        }, null, 8, ["value", "mode", "disabled"])
      ])
    ]),
    _cache[39] || (_cache[39] = _createTextVNode()),
    _createElementVNode("div", _hoisted_15, [
      _createElementVNode("div", _hoisted_16, [
        _createVNode(_component_LabeledInput, {
          value: _ctx.pool.count,
          "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.pool.count) = $event)),
          valueModifiers: { number: true },
          type: "number",
          mode: _ctx.mode,
          "label-key": "aks.nodePools.count.label",
          rules: [_ctx.poolCountValidator()],
          min: _ctx.pool.mode === 'User' ? 0 : 1,
          max: 1000,
          "data-testid": "aks-pool-count-input"
        }, null, 8, ["value", "mode", "rules", "min"])
      ]),
      _cache[22] || (_cache[22] = _createTextVNode()),
      _createElementVNode("div", _hoisted_17, [
        _createVNode(_component_LabeledInput, {
          value: _ctx.pool.maxPods,
          "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.pool.maxPods) = $event)),
          valueModifiers: { number: true },
          type: "number",
          mode: _ctx.mode,
          "label-key": "aks.nodePools.maxPods.label",
          disabled: !_ctx.pool._isNewOrUnprovisioned
        }, null, 8, ["value", "mode", "disabled"])
      ]),
      _cache[23] || (_cache[23] = _createTextVNode()),
      _createElementVNode("div", _hoisted_18, [
        _createVNode(_component_LabeledInput, {
          value: _ctx.pool.maxSurge,
          "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.pool.maxSurge) = $event)),
          mode: _ctx.mode,
          "label-key": "aks.nodePools.maxSurge.label"
        }, null, 8, ["value", "mode"])
      ])
    ]),
    _cache[40] || (_cache[40] = _createTextVNode()),
    _createElementVNode("div", _hoisted_19, [
      _createElementVNode("div", _hoisted_20, [
        _createVNode(_component_Checkbox, {
          value: _ctx.pool.enableAutoScaling,
          "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.pool.enableAutoScaling) = $event)),
          mode: _ctx.mode,
          "label-key": "aks.nodePools.enableAutoScaling.label"
        }, null, 8, ["value", "mode"])
      ]),
      _cache[25] || (_cache[25] = _createTextVNode()),
      (_ctx.pool.enableAutoScaling)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("div", _hoisted_21, [
              _createVNode(_component_LabeledInput, {
                value: _ctx.pool.minCount,
                "onUpdate:value": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.pool.minCount) = $event)),
                valueModifiers: { number: true },
                type: "number",
                mode: _ctx.mode,
                "label-key": "aks.nodePools.minCount.label",
                rules: _ctx.validationRules.min,
                min: 0,
                max: 1000
              }, null, 8, ["value", "mode", "rules"])
            ]),
            _cache[24] || (_cache[24] = _createTextVNode()),
            _createElementVNode("div", _hoisted_22, [
              _createVNode(_component_LabeledInput, {
                value: _ctx.pool.maxCount,
                "onUpdate:value": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.pool.maxCount) = $event)),
                valueModifiers: { number: true },
                type: "number",
                mode: _ctx.mode,
                "label-key": "aks.nodePools.maxCount.label",
                rules: _ctx.validationRules.max,
                min: 0,
                max: 1000
              }, null, 8, ["value", "mode", "rules"])
            ])
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _cache[41] || (_cache[41] = _createTextVNode()),
    (_ctx.pool._validation && _ctx.pool._validation._validMinMax === false)
      ? (_openBlock(), _createBlock(_component_Banner, {
          key: 0,
          color: "error",
          "label-key": "aks.errors.poolMinMax"
        }))
      : _createCommentVNode("", true),
    _cache[42] || (_cache[42] = _createTextVNode()),
    _createElementVNode("div", _hoisted_23, [
      _createElementVNode("div", _hoisted_24, [
        _createElementVNode("div", _hoisted_25, [
          _createTextVNode(_toDisplayString(_ctx.t('aks.nodePools.taints.label')) + " ", 1),
          _withDirectives(_createElementVNode("i", _hoisted_26, null, 512), [
            [_directive_clean_tooltip, _ctx.t('aks.nodePools.taints.tooltip')]
          ])
        ]),
        _cache[34] || (_cache[34] = _createTextVNode()),
        ((_ctx.taints && _ctx.taints.length) || _ctx.isView)
          ? (_openBlock(), _createElementBlock("table", _hoisted_27, [
              _createElementVNode("thead", null, [
                _createElementVNode("tr", null, [
                  _createElementVNode("th", null, [
                    _createElementVNode("label", _hoisted_28, [
                      _createTextVNode(_toDisplayString(_ctx.t('aks.nodePools.taints.key')) + " ", 1),
                      _cache[26] || (_cache[26] = _createElementVNode("span", { class: "text-error" }, "*", -1))
                    ])
                  ]),
                  _cache[28] || (_cache[28] = _createTextVNode()),
                  _createElementVNode("th", null, [
                    _createElementVNode("label", _hoisted_29, [
                      _createTextVNode(_toDisplayString(_ctx.t('aks.nodePools.taints.value')) + " ", 1),
                      _cache[27] || (_cache[27] = _createElementVNode("span", { class: "text-error" }, "*", -1))
                    ])
                  ]),
                  _cache[29] || (_cache[29] = _createTextVNode()),
                  _createElementVNode("th", null, [
                    _createElementVNode("label", _hoisted_30, _toDisplayString(_ctx.t('aks.nodePools.taints.effect')), 1)
                  ]),
                  _cache[30] || (_cache[30] = _createTextVNode()),
                  _cache[31] || (_cache[31] = _createElementVNode("th", null, null, -1))
                ])
              ]),
              _cache[33] || (_cache[33] = _createTextVNode()),
              (_ctx.taints && _ctx.taints.length)
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.taints, (keyedTaint, i) => {
                    return (_openBlock(), _createBlock(_component_Taint, {
                      key: i,
                      taint: keyedTaint.taint,
                      mode: _ctx.mode,
                      rules: _ctx.validationRules.taints,
                      "data-testid": `aks-pool-taint-${i}`,
                      "onUpdate:value": e=>_ctx.updateTaint({_id:keyedTaint._id, taint: e}, i),
                      onRemove: ($event: any) => (_ctx.removeTaint(i))
                    }, null, 8, ["taint", "mode", "rules", "data-testid", "onUpdate:value", "onRemove"]))
                  }), 128))
                : (_openBlock(), _createElementBlock("tr", _hoisted_31, _cache[32] || (_cache[32] = [
                    _createElementVNode("td", { style: {'width': '40%'} }, [
                      _createElementVNode("div", { class: "text-muted" }, "\n                  —\n                ")
                    ], -1),
                    _createTextVNode(),
                    _createElementVNode("td", { style: {'width': '40%'} }, [
                      _createElementVNode("div", { class: "text-muted" }, "\n                  —\n                ")
                    ], -1),
                    _createTextVNode(),
                    _createElementVNode("td", { style: {'width': '15%'} }, [
                      _createElementVNode("div", { class: "text-muted" }, "\n                  —\n                ")
                    ], -1)
                  ])))
            ]))
          : _createCommentVNode("", true),
        _cache[35] || (_cache[35] = _createTextVNode()),
        (!_ctx.isView)
          ? (_openBlock(), _createElementBlock("button", {
              key: 1,
              type: "button",
              class: "btn role-tertiary mt-20",
              onClick: _cache[15] || (_cache[15] = 
//@ts-ignore
(...args) => (_ctx.addTaint && _ctx.addTaint(...args)))
            }, _toDisplayString(_ctx.t('aks.nodePools.taints.addTaint')), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _cache[43] || (_cache[43] = _createTextVNode()),
    _createElementVNode("div", _hoisted_32, [
      _createElementVNode("div", _hoisted_33, [
        _createElementVNode("div", _hoisted_34, [
          _createTextVNode(_toDisplayString(_ctx.t('labels.labels.title')) + " ", 1),
          _withDirectives(_createElementVNode("i", _hoisted_35, null, 512), [
            [_directive_clean_tooltip, _ctx.t('aks.nodePools.labels.tooltip')]
          ])
        ]),
        _cache[36] || (_cache[36] = _createTextVNode()),
        _createVNode(_component_KeyValue, {
          value: _ctx.pool.nodeLabels,
          "onUpdate:value": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.pool.nodeLabels) = $event)),
          mode: _ctx.mode,
          "value-can-be-empty": true,
          "add-label": _ctx.t('aks.nodePools.labels.add'),
          "read-allowed": false,
          "data-testid": "aks-node-labels-input"
        }, null, 8, ["value", "mode", "add-label"])
      ])
    ])
  ]))
}