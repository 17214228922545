import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["alt"]

import { computed } from 'vue';

type Props = {
  borderless?: boolean;
  invisible?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ButtonMultiAction',
  props: {
    borderless: { type: Boolean },
    invisible: { type: Boolean }
  },
  emits: ['click'],
  setup(__props: any) {



const props = __props;

const buttonClass = computed(() => {
  return {
    borderless: props?.borderless,
    invisible:  props?.invisible,
  };
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    type: "button",
    class: _normalizeClass(["btn btn-sm role-multi-action actions", buttonClass.value]),
    role: "button",
    onClick: _cache[0] || (_cache[0] = (e) => _ctx.$emit('click', e))
  }, [
    _createElementVNode("i", {
      class: "icon icon-actions",
      alt: _ctx.t('sortableTable.tableActionsImgAlt')
    }, null, 8, _hoisted_1)
  ], 2))
}
}

})