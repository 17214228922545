import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withKeys as _withKeys, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "banner__icon",
  "data-testid": "banner-icon"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_t = _resolveComponent("t")!
  const _directive_clean_html = _resolveDirective("clean-html")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["banner", {
      [_ctx.color]: true,
    }]),
    role: "banner"
  }, [
    (_ctx.icon)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("i", {
            class: _normalizeClass(["icon icon-2x", _ctx.icon])
          }, null, 2)
        ]))
      : _createCommentVNode("", true),
    _cache[5] || (_cache[5] = _createTextVNode()),
    _createElementVNode("div", {
      class: _normalizeClass(["banner__content", {
        closable: _ctx.closable,
        stacked: _ctx.stacked,
        icon: _ctx.icon
      }]),
      "data-testid": "banner-content"
    }, [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        (_ctx.labelKey)
          ? (_openBlock(), _createBlock(_component_t, {
              key: 0,
              k: _ctx.labelKey,
              raw: true
            }, null, 8, ["k"]))
          : (_ctx.messageLabel)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.messageLabel), 1))
            : _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_3, null, 512)), [
                [_directive_clean_html, _ctx.nlToBr(_ctx.label)]
              ])
      ], true),
      _cache[4] || (_cache[4] = _createTextVNode()),
      (_ctx.closable)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "banner__content__closer",
            tabindex: "0",
            role: "button",
            "aria-label": _ctx.t('generic.close'),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close'))),
            onKeyup: [
              _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.$emit('close')), ["enter"])),
              _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.$emit('close')), ["space"]))
            ]
          }, _cache[3] || (_cache[3] = [
            _createElementVNode("i", {
              "data-testid": "banner-close",
              class: "icon icon-close closer-icon"
            }, null, -1)
          ]), 40, _hoisted_4))
        : _createCommentVNode("", true)
    ], 2)
  ], 2))
}