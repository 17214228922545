import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, normalizeClass as _normalizeClass, mergeProps as _mergeProps, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["onMouseenter", "onMouseleave"]
const _hoisted_2 = ["colspan"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 1,
  class: "mt-5"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["colspan"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Banner = _resolveComponent("Banner")!
  const _component_Tag = _resolveComponent("Tag")!
  const _component_PaginatedResourceTable = _resolveComponent("PaginatedResourceTable")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.hasWindowsNodes)
      ? (_openBlock(), _createBlock(_component_Banner, {
          key: 0,
          color: "info",
          label: _ctx.t('cluster.custom.registrationCommand.windowsWarning')
        }, null, 8, ["label"]))
      : _createCommentVNode("", true),
    _cache[6] || (_cache[6] = _createTextVNode()),
    _createVNode(_component_PaginatedResourceTable, _mergeProps(_ctx.$attrs, {
      schema: _ctx.schema,
      headers: _ctx.headers,
      paginationHeaders: _ctx.paginationHeaders,
      "sub-rows": true,
      fetchSecondaryResources: _ctx.fetchSecondaryResources,
      fetchPageSecondaryResources: _ctx.fetchPageSecondaryResources,
      "use-query-params-for-simple-filtering": _ctx.useQueryParamsForSimpleFiltering,
      "data-testid": "cluster-node-list"
    }), {
      "sub-row": _withCtx(({fullColspan, row, onRowMouseEnter, onRowMouseLeave}) => [
        _createElementVNode("tr", {
          class: _normalizeClass(["taints sub-row", {'empty-taints': ! row.displayTaintsAndLabels}]),
          onMouseenter: onRowMouseEnter,
          onMouseleave: onRowMouseLeave
        }, [
          (row.displayTaintsAndLabels)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _cache[2] || (_cache[2] = _createElementVNode("td", null, " ", -1)),
                _cache[3] || (_cache[3] = _createTextVNode()),
                _cache[4] || (_cache[4] = _createElementVNode("td", null, " ", -1)),
                _cache[5] || (_cache[5] = _createTextVNode()),
                _createElementVNode("td", {
                  colspan: fullColspan-2
                }, [
                  (row.spec.taints && row.spec.taints.length)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                        _createTextVNode(_toDisplayString(_ctx.t('node.list.nodeTaint')) + ":\n                ", 1),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.spec.taints, (taint, i) => {
                          return (_openBlock(), _createBlock(_component_Tag, {
                            key: i,
                            class: "mr-5 mt-2"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(taint.key) + "=" + _toDisplayString(taint.value) + ":" + _toDisplayString(taint.effect), 1)
                            ]),
                            _: 2
                          }, 1024))
                        }), 128))
                      ]))
                    : _createCommentVNode("", true),
                  _cache[1] || (_cache[1] = _createTextVNode()),
                  (!!row.customLabelCount)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                        _createTextVNode(_toDisplayString(_ctx.t('node.list.nodeLabels')) + ":\n                ", 1),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.customLabels, (label, i) => {
                          return (_openBlock(), _createElementBlock("span", {
                            key: i,
                            class: "mt-5 labels"
                          }, [
                            (i < 7)
                              ? (_openBlock(), _createBlock(_component_Tag, {
                                  key: 0,
                                  class: "mr-2 label"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(label), 1)
                                  ]),
                                  _: 2
                                }, 1024))
                              : (i > 6 && row.displayLabels)
                                ? (_openBlock(), _createBlock(_component_Tag, {
                                    key: 1,
                                    class: "mr-2 label"
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(label), 1)
                                    ]),
                                    _: 2
                                  }, 1024))
                                : _createCommentVNode("", true)
                          ]))
                        }), 128)),
                        _cache[0] || (_cache[0] = _createTextVNode()),
                        (row.customLabelCount > 7)
                          ? (_openBlock(), _createElementBlock("a", {
                              key: 0,
                              href: "#",
                              onClick: _withModifiers(($event: any) => (_ctx.toggleLabels(row)), ["prevent"])
                            }, _toDisplayString(_ctx.t(`node.list.${row.displayLabels? 'hideLabels' : 'showLabels'}`)), 9, _hoisted_5))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true)
                ], 8, _hoisted_2)
              ], 64))
            : (_openBlock(), _createElementBlock("td", {
                key: 1,
                colspan: fullColspan
              }, "\n \n          ", 8, _hoisted_6))
        ], 42, _hoisted_1)
      ]),
      _: 1
    }, 16, ["schema", "headers", "paginationHeaders", "fetchSecondaryResources", "fetchPageSecondaryResources", "use-query-params-for-simple-filtering"])
  ]))
}