import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createSlots as _createSlots } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row mb-10"
}
const _hoisted_2 = { class: "col span-6" }
const _hoisted_3 = { class: "col span-6" }
const _hoisted_4 = {
  key: 1,
  "data-testid": "crueks-form"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_LabeledInput = _resolveComponent("LabeledInput")!
  const _component_AccountAccess = _resolveComponent("AccountAccess")!
  const _component_Import = _resolveComponent("Import")!
  const _component_Accordion = _resolveComponent("Accordion")!
  const _component_NodeGroup = _resolveComponent("NodeGroup")!
  const _component_Tab = _resolveComponent("Tab")!
  const _component_Tabbed = _resolveComponent("Tabbed")!
  const _component_Config = _resolveComponent("Config")!
  const _component_Networking = _resolveComponent("Networking")!
  const _component_Logging = _resolveComponent("Logging")!
  const _component_AgentConfiguration = _resolveComponent("AgentConfiguration")!
  const _component_Banner = _resolveComponent("Banner")!
  const _component_ClusterMembershipEditor = _resolveComponent("ClusterMembershipEditor")!
  const _component_Labels = _resolveComponent("Labels")!
  const _component_CruResource = _resolveComponent("CruResource")!

  return (_ctx.fetchState.pending)
    ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
    : (_openBlock(), _createBlock(_component_CruResource, {
        key: 1,
        ref: "cruresource",
        resource: _ctx.value,
        mode: _ctx.mode,
        "can-yaml": false,
        "done-route": _ctx.doneRoute,
        errors: _ctx.fvUnreportedValidationErrors,
        "validation-passed": _ctx.fvFormIsValid,
        onError: _cache[24] || (_cache[24] = e=>_ctx.errors=e),
        onFinish: _ctx.save,
        onCancel: _ctx.done
      }, _createSlots({
        default: _withCtx(() => [
          (_ctx.hasCredential)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_LabeledInput, {
                    required: "",
                    "label-key": "eks.clusterName.label",
                    value: _ctx.normanCluster.name,
                    mode: _ctx.mode,
                    rules: _ctx.fvGetAndReportPathRules('name'),
                    "data-testid": "eks-name-input",
                    "onUpdate:value": _ctx.setClusterName
                  }, null, 8, ["value", "mode", "rules", "onUpdate:value"])
                ]),
                _cache[25] || (_cache[25] = _createTextVNode()),
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_LabeledInput, {
                    value: _ctx.normanCluster.description,
                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.normanCluster.description) = $event)),
                    mode: _ctx.mode,
                    "label-key": "nameNsDescription.description.label",
                    placeholder: _ctx.t('nameNsDescription.description.placeholder')
                  }, null, 8, ["value", "mode", "placeholder"])
                ])
              ]))
            : _createCommentVNode("", true),
          _cache[36] || (_cache[36] = _createTextVNode()),
          _createVNode(_component_AccountAccess, {
            credential: _ctx.config.amazonCredentialSecret,
            mode: _ctx.mode,
            region: _ctx.config.region,
            onCancelCredential: _ctx.cancelCredential,
            onUpdateRegion: _ctx.updateRegion,
            onUpdateCredential: _ctx.updateCredential,
            onError: _cache[1] || (_cache[1] = e=>_ctx.errors.push(e))
          }, null, 8, ["credential", "mode", "region", "onCancelCredential", "onUpdateRegion", "onUpdateCredential"]),
          _cache[37] || (_cache[37] = _createTextVNode()),
          (_ctx.hasCredential)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                (_ctx.isImport)
                  ? (_openBlock(), _createBlock(_component_Accordion, {
                      key: 0,
                      class: "mb-20",
                      title: _ctx.t('eks.accordionHeaders.cluster'),
                      "open-initially": true
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Import, {
                          "cluster-name": _ctx.config.displayName,
                          "onUpdate:clusterName": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.config.displayName) = $event)),
                          "enable-network-policy": _ctx.normanCluster.enableNetworkPolicy,
                          "onUpdate:enableNetworkPolicy": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.normanCluster.enableNetworkPolicy) = $event)),
                          credential: _ctx.config.amazonCredentialSecret,
                          mode: _ctx.mode,
                          region: _ctx.config.region,
                          rules: { displayName: _ctx.fvGetAndReportPathRules('displayName') },
                          onError: _cache[4] || (_cache[4] = e=>_ctx.errors.push(e))
                        }, null, 8, ["cluster-name", "enable-network-policy", "credential", "mode", "region", "rules"])
                      ]),
                      _: 1
                    }, 8, ["title"]))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createElementVNode("div", null, [
                        _createElementVNode("h3", null, _toDisplayString(_ctx.t('eks.nodeGroups.title')), 1)
                      ]),
                      _cache[26] || (_cache[26] = _createTextVNode()),
                      _createVNode(_component_Tabbed, {
                        class: "mb-20",
                        "side-tabs": true,
                        "show-tabs-add-remove": _ctx.mode !== _ctx.VIEW,
                        onRemoveTab: _cache[5] || (_cache[5] = ($event: any) => (_ctx.removeGroup($event))),
                        onAddTab: _cache[6] || (_cache[6] = ($event: any) => (_ctx.addGroup()))
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nodeGroups, (node, i) => {
                            return (_openBlock(), _createBlock(_component_Tab, {
                              key: i,
                              label: node.nodegroupName || _ctx.t('eks.nodeGroups.unnamed'),
                              name: `${node.nodegroupName} ${i}`
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_NodeGroup, {
                                  "node-role": node.nodeRole,
                                  "onUpdate:nodeRole": ($event: any) => ((node.nodeRole) = $event),
                                  "launch-template": node.launchTemplate,
                                  "onUpdate:launchTemplate": ($event: any) => ((node.launchTemplate) = $event),
                                  "nodegroup-name": node.nodegroupName,
                                  "onUpdate:nodegroupName": ($event: any) => ((node.nodegroupName) = $event),
                                  "ec2-ssh-key": node.ec2SshKey,
                                  "onUpdate:ec2SshKey": ($event: any) => ((node.ec2SshKey) = $event),
                                  tags: node.tags,
                                  "onUpdate:tags": ($event: any) => ((node.tags) = $event),
                                  "resource-tags": node.resourceTags,
                                  "onUpdate:resourceTags": ($event: any) => ((node.resourceTags) = $event),
                                  "disk-size": node.diskSize,
                                  "onUpdate:diskSize": ($event: any) => ((node.diskSize) = $event),
                                  "image-id": node.imageId,
                                  "onUpdate:imageId": ($event: any) => ((node.imageId) = $event),
                                  "instance-type": node.instanceType,
                                  "onUpdate:instanceType": ($event: any) => ((node.instanceType) = $event),
                                  "spot-instance-types": node.spotInstanceTypes,
                                  "onUpdate:spotInstanceTypes": ($event: any) => ((node.spotInstanceTypes) = $event),
                                  "user-data": node.userData,
                                  "onUpdate:userData": ($event: any) => ((node.userData) = $event),
                                  gpu: node.gpu,
                                  "onUpdate:gpu": ($event: any) => ((node.gpu) = $event),
                                  "desired-size": node.desiredSize,
                                  "onUpdate:desiredSize": ($event: any) => ((node.desiredSize) = $event),
                                  "min-size": node.minSize,
                                  "onUpdate:minSize": ($event: any) => ((node.minSize) = $event),
                                  "max-size": node.maxSize,
                                  "onUpdate:maxSize": ($event: any) => ((node.maxSize) = $event),
                                  "request-spot-instances": node.requestSpotInstances,
                                  "onUpdate:requestSpotInstances": ($event: any) => ((node.requestSpotInstances) = $event),
                                  labels: node.labels,
                                  "onUpdate:labels": ($event: any) => ((node.labels) = $event),
                                  version: node.version,
                                  "onUpdate:version": ($event: any) => ((node.version) = $event),
                                  "pool-is-upgrading": node._isUpgrading,
                                  "onUpdate:poolIsUpgrading": ($event: any) => ((node._isUpgrading) = $event),
                                  rules: {
                nodegroupName: _ctx.fvGetAndReportPathRules('nodegroupNames'),
                maxSize: _ctx.fvGetAndReportPathRules('maxSize'),
                minSize: _ctx.fvGetAndReportPathRules('minSize'),
                desiredSize: _ctx.fvGetAndReportPathRules('desiredSize'),
                instanceType: _ctx.fvGetAndReportPathRules('instanceType'),
                diskSize: _ctx.fvGetAndReportPathRules('diskSize'),
                minMaxDesired: _ctx.fvGetAndReportPathRules('minMaxDesired')
              },
                                  "cluster-version": _ctx.config.kubernetesVersion,
                                  "original-cluster-version": _ctx.originalVersion,
                                  region: _ctx.config.region,
                                  "amazon-credential-secret": _ctx.config.amazonCredentialSecret,
                                  "is-new-or-unprovisioned": _ctx.isNewOrUnprovisioned,
                                  "pool-is-new": node._isNew,
                                  mode: _ctx.mode,
                                  "instance-type-options": _ctx.instanceTypeOptions,
                                  "spot-instance-type-options": _ctx.spotInstanceTypeOptions,
                                  "launch-templates": _ctx.launchTemplates,
                                  "ec2-roles": _ctx.ec2Roles,
                                  "ssh-key-pairs": _ctx.sshKeyPairs,
                                  "loading-instance-types": _ctx.loadingInstanceTypes,
                                  "loading-roles": _ctx.loadingIam,
                                  "loading-launch-templates": _ctx.loadingLaunchTemplates,
                                  "loading-ssh-key-pairs": _ctx.loadingSshKeyPairs,
                                  "norman-cluster": _ctx.normanCluster
                                }, null, 8, ["node-role", "onUpdate:nodeRole", "launch-template", "onUpdate:launchTemplate", "nodegroup-name", "onUpdate:nodegroupName", "ec2-ssh-key", "onUpdate:ec2SshKey", "tags", "onUpdate:tags", "resource-tags", "onUpdate:resourceTags", "disk-size", "onUpdate:diskSize", "image-id", "onUpdate:imageId", "instance-type", "onUpdate:instanceType", "spot-instance-types", "onUpdate:spotInstanceTypes", "user-data", "onUpdate:userData", "gpu", "onUpdate:gpu", "desired-size", "onUpdate:desiredSize", "min-size", "onUpdate:minSize", "max-size", "onUpdate:maxSize", "request-spot-instances", "onUpdate:requestSpotInstances", "labels", "onUpdate:labels", "version", "onUpdate:version", "pool-is-upgrading", "onUpdate:poolIsUpgrading", "rules", "cluster-version", "original-cluster-version", "region", "amazon-credential-secret", "is-new-or-unprovisioned", "pool-is-new", "mode", "instance-type-options", "spot-instance-type-options", "launch-templates", "ec2-roles", "ssh-key-pairs", "loading-instance-types", "loading-roles", "loading-launch-templates", "loading-ssh-key-pairs", "norman-cluster"])
                              ]),
                              _: 2
                            }, 1032, ["label", "name"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["show-tabs-add-remove"]),
                      _cache[27] || (_cache[27] = _createTextVNode()),
                      _createVNode(_component_Accordion, {
                        class: "mb-20",
                        title: _ctx.t('eks.accordionHeaders.cluster'),
                        "open-initially": true
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Config, {
                            "kubernetes-version": _ctx.config.kubernetesVersion,
                            "onUpdate:kubernetesVersion": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.config.kubernetesVersion) = $event)),
                            "enable-network-policy": _ctx.normanCluster.enableNetworkPolicy,
                            "onUpdate:enableNetworkPolicy": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.normanCluster.enableNetworkPolicy) = $event)),
                            "ebs-c-s-i-driver": _ctx.config.ebsCSIDriver,
                            "onUpdate:ebsCSIDriver": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.config.ebsCSIDriver) = $event)),
                            "service-role": _ctx.config.serviceRole,
                            "onUpdate:serviceRole": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.config.serviceRole) = $event)),
                            "kms-key": _ctx.config.kmsKey,
                            "onUpdate:kmsKey": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.config.kmsKey) = $event)),
                            "secrets-encryption": _ctx.config.secretsEncryption,
                            "onUpdate:secretsEncryption": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.config.secretsEncryption) = $event)),
                            tags: _ctx.config.tags,
                            "onUpdate:tags": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.config.tags) = $event)),
                            mode: _ctx.mode,
                            config: _ctx.config,
                            "eks-roles": _ctx.eksRoles,
                            "loading-iam": _ctx.loadingIam,
                            "original-version": _ctx.originalVersion,
                            "data-testid": "eks-config-section",
                            onError: _cache[14] || (_cache[14] = ($event: any) => (_ctx.errors.push($event)))
                          }, null, 8, ["kubernetes-version", "enable-network-policy", "ebs-c-s-i-driver", "service-role", "kms-key", "secrets-encryption", "tags", "mode", "config", "eks-roles", "loading-iam", "original-version"])
                        ]),
                        _: 1
                      }, 8, ["title"]),
                      _cache[28] || (_cache[28] = _createTextVNode()),
                      _createVNode(_component_Accordion, {
                        class: "mb-20",
                        title: _ctx.t('eks.accordionHeaders.networking'),
                        "open-initially": true
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Networking, {
                            "public-access": _ctx.config.publicAccess,
                            "onUpdate:publicAccess": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.config.publicAccess) = $event)),
                            "private-access": _ctx.config.privateAccess,
                            "onUpdate:privateAccess": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.config.privateAccess) = $event)),
                            "public-access-sources": _ctx.config.publicAccessSources,
                            "onUpdate:publicAccessSources": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.config.publicAccessSources) = $event)),
                            subnets: _ctx.config.subnets,
                            "onUpdate:subnets": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.config.subnets) = $event)),
                            "security-groups": _ctx.config.securityGroups,
                            "onUpdate:securityGroups": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.config.securityGroups) = $event)),
                            mode: _ctx.mode,
                            region: _ctx.config.region,
                            "amazon-credential-secret": _ctx.config.amazonCredentialSecret,
                            "status-subnets": _ctx.statusSubnets,
                            rules: {subnets:_ctx.fvGetAndReportPathRules('subnets')}
                          }, null, 8, ["public-access", "private-access", "public-access-sources", "subnets", "security-groups", "mode", "region", "amazon-credential-secret", "status-subnets", "rules"])
                        ]),
                        _: 1
                      }, 8, ["title"]),
                      _cache[29] || (_cache[29] = _createTextVNode()),
                      _createVNode(_component_Accordion, {
                        class: "mb-20",
                        title: _ctx.t('eks.accordionHeaders.logging'),
                        "open-initially": true
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Logging, {
                            "logging-types": _ctx.config.loggingTypes,
                            "onUpdate:loggingTypes": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.config.loggingTypes) = $event)),
                            mode: _ctx.mode,
                            config: _ctx.config
                          }, null, 8, ["logging-types", "mode", "config"])
                        ]),
                        _: 1
                      }, 8, ["title"])
                    ], 64)),
                _cache[31] || (_cache[31] = _createTextVNode()),
                _createVNode(_component_Accordion, {
                  class: "mb-20",
                  title: _ctx.t('eks.accordionHeaders.clusterAgent')
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_AgentConfiguration, {
                      value: _ctx.normanCluster.clusterAgentDeploymentCustomization,
                      "onUpdate:value": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.normanCluster.clusterAgentDeploymentCustomization) = $event)),
                      mode: _ctx.mode,
                      type: "cluster"
                    }, null, 8, ["value", "mode"])
                  ]),
                  _: 1
                }, 8, ["title"]),
                _cache[32] || (_cache[32] = _createTextVNode()),
                _createVNode(_component_Accordion, {
                  class: "mb-20",
                  title: _ctx.t('eks.accordionHeaders.fleetAgent')
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_AgentConfiguration, {
                      value: _ctx.normanCluster.fleetAgentDeploymentCustomization,
                      "onUpdate:value": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.normanCluster.fleetAgentDeploymentCustomization) = $event)),
                      mode: _ctx.mode,
                      type: "fleet"
                    }, null, 8, ["value", "mode"])
                  ]),
                  _: 1
                }, 8, ["title"]),
                _cache[33] || (_cache[33] = _createTextVNode()),
                _createVNode(_component_Accordion, {
                  class: "mb-20",
                  title: _ctx.t('members.memberRoles')
                }, {
                  default: _withCtx(() => [
                    (_ctx.isEdit)
                      ? (_openBlock(), _createBlock(_component_Banner, {
                          key: 0,
                          color: "info"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t('cluster.memberRoles.removeMessage')), 1)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    _cache[30] || (_cache[30] = _createTextVNode()),
                    (_ctx.canManageMembers)
                      ? (_openBlock(), _createBlock(_component_ClusterMembershipEditor, {
                          key: 1,
                          mode: _ctx.mode,
                          "parent-id": _ctx.normanCluster.id ? _ctx.normanCluster.id : undefined,
                          onMembershipUpdate: _ctx.onMembershipUpdate
                        }, null, 8, ["mode", "parent-id", "onMembershipUpdate"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["title"]),
                _cache[34] || (_cache[34] = _createTextVNode()),
                _createVNode(_component_Accordion, {
                  class: "mb-20",
                  title: _ctx.t('generic.labelsAndAnnotations')
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Labels, {
                      value: _ctx.normanCluster,
                      "onUpdate:value": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.normanCluster) = $event)),
                      mode: _ctx.mode
                    }, null, 8, ["value", "mode"])
                  ]),
                  _: 1
                }, 8, ["title"])
              ]))
            : _createCommentVNode("", true),
          _cache[38] || (_cache[38] = _createTextVNode())
        ]),
        _: 2
      }, [
        (!_ctx.hasCredential)
          ? {
              name: "form-footer",
              fn: _withCtx(() => [
                _cache[35] || (_cache[35] = _createElementVNode("div", null, null, -1))
              ]),
              key: "0"
            }
          : undefined
      ]), 1032, ["resource", "mode", "done-route", "errors", "validation-passed", "onFinish", "onCancel"]))
}