import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "input-known-ssh-hosts labeled-input",
  "data-testid": "input-known-ssh-hosts"
}
const _hoisted_2 = {
  class: "hosts-input",
  "data-testid": "input-known-ssh-hosts_summary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KnownHostsEditDialog = _resolveComponent("KnownHostsEditDialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", null, _toDisplayString(_ctx.t('secret.ssh.knownHosts')), 1),
    _cache[3] || (_cache[3] = _createTextVNode()),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.summary), 1),
    _cache[4] || (_cache[4] = _createTextVNode()),
    (!_ctx.isViewMode)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("button", {
            ref: "button",
            "data-testid": "input-known-ssh-hosts_open-dialog",
            class: "show-dialog-btn btn",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openDialog && _ctx.openDialog(...args)))
          }, _cache[1] || (_cache[1] = [
            _createElementVNode("i", { class: "icon icon-edit" }, null, -1)
          ]), 512),
          _cache[2] || (_cache[2] = _createTextVNode()),
          _createVNode(_component_KnownHostsEditDialog, {
            ref: "editDialog",
            value: _ctx.value,
            mode: _ctx.mode,
            onClosed: _ctx.dialogClosed
          }, null, 8, ["value", "mode", "onClosed"])
        ], 64))
      : _createCommentVNode("", true)
  ]))
}