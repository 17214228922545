import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode, renderList as _renderList, createVNode as _createVNode, withModifiers as _withModifiers, withKeys as _withKeys, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "radio-group label"
}
const _hoisted_2 = {
  key: 2,
  class: "icon icon-info icon-lg"
}
const _hoisted_3 = {
  key: 3,
  class: "icon icon-info icon-lg"
}
const _hoisted_4 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_t = _resolveComponent("t")!
  const _component_RadioButton = _resolveComponent("RadioButton")!
  const _directive_clean_tooltip = _resolveDirective("clean-tooltip")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.label || _ctx.labelKey || _ctx.tooltip || _ctx.tooltipKey || _ctx.$slots.label)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "label", {}, () => [
            _createElementVNode("h3", null, [
              (_ctx.labelKey)
                ? (_openBlock(), _createBlock(_component_t, {
                    key: 0,
                    k: _ctx.labelKey
                  }, null, 8, ["k"]))
                : (_ctx.label)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createTextVNode(_toDisplayString(_ctx.label), 1)
                    ], 64))
                  : _createCommentVNode("", true),
              _cache[4] || (_cache[4] = _createTextVNode()),
              (_ctx.tooltipKey)
                ? _withDirectives((_openBlock(), _createElementBlock("i", _hoisted_2, null, 512)), [
                    [_directive_clean_tooltip, _ctx.t(_ctx.tooltipKey)]
                  ])
                : (_ctx.tooltip)
                  ? _withDirectives((_openBlock(), _createElementBlock("i", _hoisted_3, null, 512)), [
                      [_directive_clean_tooltip, _ctx.tooltip]
                    ])
                  : _createCommentVNode("", true)
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _cache[5] || (_cache[5] = _createTextVNode()),
    _createElementVNode("div", {
      ref: "radioGroup",
      role: "radiogroup",
      "aria-label": _ctx.radioGroupLabel,
      class: _normalizeClass(["radio-group", {'row':_ctx.row}]),
      tabindex: "0",
      onKeydown: [
        _cache[1] || (_cache[1] = _withKeys(_withModifiers(($event: any) => (_ctx.clickNext(1)), ["prevent","stop"]), ["down"])),
        _cache[2] || (_cache[2] = _withKeys(_withModifiers(($event: any) => (_ctx.clickNext(-1)), ["prevent","stop"]), ["up"])),
        _cache[3] || (_cache[3] = _withKeys(_withModifiers(() => {}, ["stop","prevent"]), ["space","enter"]))
      ]
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.normalizedOptions, (option, i) => {
        return (_openBlock(), _createElementBlock("div", { key: i }, [
          _renderSlot(_ctx.$slots, i, {
            vBind: _ctx.$attrs,
            option: option,
            isDisabled: _ctx.isDisabled
          }, () => [
            _createVNode(_component_RadioButton, {
              name: _ctx.name,
              value: _ctx.value,
              label: option.label,
              description: option.description,
              val: option.value,
              disabled: _ctx.isDisabled,
              "data-testid": `radio-button-${i}`,
              mode: _ctx.mode,
              "prevent-focus-on-radio-groups": true,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:value', $event)))
            }, null, 8, ["name", "value", "label", "description", "val", "disabled", "data-testid", "mode"])
          ])
        ]))
      }), 128))
    ], 42, _hoisted_4)
  ]))
}