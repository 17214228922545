import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PaginatedResourceTable = _resolveComponent("PaginatedResourceTable")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_PaginatedResourceTable, {
      schema: _ctx.schema,
      "local-filter": _ctx.filterRowsLocal,
      "api-filter": _ctx.filterRowsApi,
      "use-query-params-for-simple-filtering": _ctx.useQueryParamsForSimpleFiltering,
      "data-testid": "app-cluster-repo-list"
    }, null, 8, ["schema", "local-filter", "api-filter", "use-query-params-for-simple-filtering"])
  ]))
}