import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row mb-10" }
const _hoisted_2 = { class: "col span-4" }
const _hoisted_3 = { class: "col span-4" }
const _hoisted_4 = { class: "col span-4" }
const _hoisted_5 = { class: "row mb-10" }
const _hoisted_6 = { class: "col span-4 checkbox-column" }
const _hoisted_7 = { class: "col span-4" }
const _hoisted_8 = { class: "col span-4" }
const _hoisted_9 = { class: "mt-20" }
const _hoisted_10 = { class: "row mb-10" }
const _hoisted_11 = { class: "col span-6" }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { class: "col span-6" }
const _hoisted_14 = { class: "row mb-10" }
const _hoisted_15 = { class: "col span-6" }
const _hoisted_16 = { class: "col span-6" }
const _hoisted_17 = { class: "row mb-10" }
const _hoisted_18 = { class: "col span-4" }
const _hoisted_19 = { class: "col span-4" }
const _hoisted_20 = { class: "col span-4" }
const _hoisted_21 = { class: "row mb-10" }
const _hoisted_22 = { class: "col span-3" }
const _hoisted_23 = { class: "row mb-10" }
const _hoisted_24 = { class: "col span-12" }
const _hoisted_25 = { class: "row mb-20" }
const _hoisted_26 = { class: "col span-12" }
const _hoisted_27 = { class: "row mb-10" }
const _hoisted_28 = { class: "col span-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LabeledInput = _resolveComponent("LabeledInput")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_LabeledSelect = _resolveComponent("LabeledSelect")!
  const _component_UnitInput = _resolveComponent("UnitInput")!
  const _component_Taints = _resolveComponent("Taints")!
  const _component_KeyValue = _resolveComponent("KeyValue")!
  const _component_ArrayList = _resolveComponent("ArrayList")!
  const _component_AuthScopes = _resolveComponent("AuthScopes")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("h3", null, _toDisplayString(_ctx.t('gke.groupDetails')), 1),
    _cache[30] || (_cache[30] = _createTextVNode()),
    _cache[31] || (_cache[31] = _createElementVNode("hr", null, null, -1)),
    _cache[32] || (_cache[32] = _createTextVNode()),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_LabeledInput, {
          mode: _ctx.mode,
          value: _ctx.name,
          "label-key": "gke.groupName.label",
          disabled: !_ctx.isNew,
          rules: _ctx.rules.poolName,
          required: "",
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:name', $event)))
        }, null, 8, ["mode", "value", "disabled", "rules"])
      ]),
      _cache[20] || (_cache[20] = _createTextVNode()),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_LabeledInput, {
          type: "number",
          mode: _ctx.mode,
          value: _ctx.initialNodeCount,
          "label-key": "gke.initialNodeCount.label",
          rules: _ctx.rules.initialNodeCount,
          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:initialNodeCount', $event)))
        }, null, 8, ["mode", "value", "rules"])
      ]),
      _cache[21] || (_cache[21] = _createTextVNode()),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_LabeledInput, {
          type: "number",
          mode: _ctx.mode,
          value: _ctx.maxPodsConstraint,
          "label-key": "gke.maxPodsConstraint.label",
          disabled: !_ctx.isNew,
          "data-testid": "gke-max-pod-constraint-input",
          "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:maxPodsConstraint', $event)))
        }, null, 8, ["mode", "value", "disabled"])
      ])
    ]),
    _cache[33] || (_cache[33] = _createTextVNode()),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_Checkbox, {
          mode: _ctx.mode,
          value: _ctx.autoscaling,
          "label-key": "gke.autoscaling.label",
          "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('update:autoscaling', $event)))
        }, null, 8, ["mode", "value"]),
        _cache[22] || (_cache[22] = _createTextVNode()),
        _createVNode(_component_Checkbox, {
          mode: _ctx.mode,
          value: _ctx.autoRepair,
          "label-key": "gke.autoRepair.label",
          "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('update:autoRepair', $event)))
        }, null, 8, ["mode", "value"]),
        _cache[23] || (_cache[23] = _createTextVNode()),
        _createVNode(_component_Checkbox, {
          mode: _ctx.mode,
          value: _ctx.autoUpgrade,
          "label-key": "gke.autoUpgrade.label",
          "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('update:autoUpgrade', $event)))
        }, null, 8, ["mode", "value"])
      ]),
      _cache[25] || (_cache[25] = _createTextVNode()),
      (_ctx.autoscaling)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_LabeledInput, {
                mode: _ctx.mode,
                type: "number",
                value: _ctx.minNodeCount,
                "label-key": "gke.minNodeCount.label",
                "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('update:minNodeCount', $event)))
              }, null, 8, ["mode", "value"])
            ]),
            _cache[24] || (_cache[24] = _createTextVNode()),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_LabeledInput, {
                mode: _ctx.mode,
                type: "number",
                value: _ctx.maxNodeCount,
                "label-key": "gke.maxNodeCount.label",
                "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('update:maxNodeCount', $event)))
              }, null, 8, ["mode", "value"])
            ])
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _cache[34] || (_cache[34] = _createTextVNode()),
    _createElementVNode("h3", _hoisted_9, _toDisplayString(_ctx.t('gke.nodeDetails')), 1),
    _cache[35] || (_cache[35] = _createTextVNode()),
    _cache[36] || (_cache[36] = _createElementVNode("hr", null, null, -1)),
    _cache[37] || (_cache[37] = _createTextVNode()),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        (_ctx.upgradeAvailable)
          ? (_openBlock(), _createBlock(_component_Checkbox, {
              key: 0,
              value: _ctx.upgradeKubernetesVersion,
              "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.upgradeKubernetesVersion) = $event)),
              mode: _ctx.mode,
              label: _ctx.t('gke.version.upgrade', {clusterKubernetesVersion: _ctx.clusterKubernetesVersion, version: _ctx.initialVersion}),
              "data-testid": "gke-k8s-upgrade-checkbox"
            }, null, 8, ["value", "mode", "label"]))
          : (_openBlock(), _createElementBlock("div", _hoisted_12, [
              _createVNode(_component_LabeledInput, {
                "label-key": "gke.version.label",
                value: _ctx.version,
                disabled: "",
                "data-testid": "gke-k8s-display"
              }, null, 8, ["value"])
            ]))
      ]),
      _cache[26] || (_cache[26] = _createTextVNode()),
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_LabeledSelect, {
          mode: _ctx.mode,
          options: _ctx.serviceAccountOptions,
          loading: _ctx.loadingServiceAccounts,
          value: _ctx.selectedServiceAccount,
          "label-key": "gke.serviceAccount.label",
          disabled: !_ctx.isNew,
          "data-testid": "gke-service-account-select",
          onSelecting: _cache[9] || (_cache[9] = e=>_ctx.selectedServiceAccount=e )
        }, null, 8, ["mode", "options", "loading", "value", "disabled"])
      ])
    ]),
    _cache[38] || (_cache[38] = _createTextVNode()),
    _createElementVNode("div", _hoisted_14, [
      _createElementVNode("div", _hoisted_15, [
        _createVNode(_component_LabeledSelect, {
          value: _ctx.selectedImageType,
          mode: _ctx.mode,
          options: _ctx.imageTypeOptions,
          "label-key": "gke.imageType.label",
          onSelecting: _cache[10] || (_cache[10] = ($event: any) => (_ctx.selectedImageType=$event))
        }, null, 8, ["value", "mode", "options"])
      ]),
      _cache[27] || (_cache[27] = _createTextVNode()),
      _createElementVNode("div", _hoisted_16, [
        _createVNode(_component_LabeledSelect, {
          mode: _ctx.mode,
          options: _ctx.machineTypeOptions,
          loading: _ctx.loadingMachineTypes,
          value: _ctx.selectedMachineType,
          "label-key": "gke.machineType.label",
          disabled: !_ctx.isNew,
          onSelecting: _cache[11] || (_cache[11] = ($event: any) => (_ctx.selectedMachineType = $event))
        }, null, 8, ["mode", "options", "loading", "value", "disabled"])
      ])
    ]),
    _cache[39] || (_cache[39] = _createTextVNode()),
    _createElementVNode("div", _hoisted_17, [
      _createElementVNode("div", _hoisted_18, [
        _createVNode(_component_LabeledSelect, {
          mode: _ctx.mode,
          options: _ctx.diskTypeOptions,
          value: _ctx.selectedDiskType,
          "label-key": "gke.diskType.label",
          disabled: !_ctx.isNew,
          onSelecting: _cache[12] || (_cache[12] = ($event: any) => (_ctx.selectedDiskType=$event))
        }, null, 8, ["mode", "options", "value", "disabled"])
      ]),
      _cache[28] || (_cache[28] = _createTextVNode()),
      _createElementVNode("div", _hoisted_19, [
        _createVNode(_component_UnitInput, {
          mode: _ctx.mode,
          value: _ctx.diskSizeGb,
          "label-key": "gke.diskSizeGb.label",
          suffix: "GB",
          disabled: !_ctx.isNew,
          rules: _ctx.rules.diskSizeGb,
          "onUpdate:value": _cache[13] || (_cache[13] = ($event: any) => (_ctx.$emit('update:diskSizeGb', $event)))
        }, null, 8, ["mode", "value", "disabled", "rules"])
      ]),
      _cache[29] || (_cache[29] = _createTextVNode()),
      _createElementVNode("div", _hoisted_20, [
        _createVNode(_component_LabeledInput, {
          mode: _ctx.mode,
          value: _ctx.localSsdCount,
          "label-key": "gke.localSsdCount.label",
          disabled: !_ctx.isNew,
          rules: _ctx.rules.ssdCount,
          "onUpdate:value": _cache[14] || (_cache[14] = ($event: any) => (_ctx.$emit('update:localSsdCount', $event)))
        }, null, 8, ["mode", "value", "disabled", "rules"])
      ])
    ]),
    _cache[40] || (_cache[40] = _createTextVNode()),
    _createElementVNode("div", _hoisted_21, [
      _createElementVNode("div", _hoisted_22, [
        _createVNode(_component_Checkbox, {
          "label-key": "gke.preemptible.label",
          mode: _ctx.mode,
          value: _ctx.preemptible,
          disabled: !_ctx.isNew,
          "onUpdate:value": _cache[15] || (_cache[15] = ($event: any) => (_ctx.$emit('update:preemptible', $event)))
        }, null, 8, ["mode", "value", "disabled"])
      ])
    ]),
    _cache[41] || (_cache[41] = _createTextVNode()),
    _createElementVNode("div", _hoisted_23, [
      _createElementVNode("div", _hoisted_24, [
        _createVNode(_component_Taints, {
          mode: _ctx.mode,
          value: _ctx.taints,
          disabled: !_ctx.isNew,
          "effect-values": {NO_SCHEDULE:'NoSchedule', PREFER_NO_SCHEDULE: 'PreferNoSchedule', NO_EXECUTE: 'NoExecute'},
          "data-testid": "gke-taints-comp",
          "onUpdate:value": _cache[16] || (_cache[16] = ($event: any) => (_ctx.$emit('update:taints', $event)))
        }, null, 8, ["mode", "value", "disabled"])
      ])
    ]),
    _cache[42] || (_cache[42] = _createTextVNode()),
    _createElementVNode("div", _hoisted_25, [
      _createElementVNode("div", _hoisted_26, [
        _createVNode(_component_KeyValue, {
          mode: _ctx.mode,
          value: _ctx.labels,
          title: _ctx.t('gke.nodeLabels.label'),
          "read-allowed": false,
          "as-map": true,
          "title-protip": _ctx.t('gke.nodeLabels.tooltip'),
          "add-label": _ctx.t('gke.nodeLabels.add'),
          disabled: !_ctx.isNew,
          "onUpdate:value": _cache[17] || (_cache[17] = ($event: any) => (_ctx.$emit('update:labels', $event)))
        }, null, 8, ["mode", "value", "title", "title-protip", "add-label", "disabled"])
      ])
    ]),
    _cache[43] || (_cache[43] = _createTextVNode()),
    _createElementVNode("div", _hoisted_27, [
      _createElementVNode("div", _hoisted_28, [
        _createVNode(_component_ArrayList, {
          mode: _ctx.isNew ? _ctx.mode : _ctx._VIEW,
          value: _ctx.tags,
          title: _ctx.t('gke.tags.label'),
          "add-label": _ctx.t('gke.tags.add'),
          "onUpdate:value": _cache[18] || (_cache[18] = ($event: any) => (_ctx.$emit('update:tags', $event)))
        }, null, 8, ["mode", "value", "title", "add-label"])
      ])
    ]),
    _cache[44] || (_cache[44] = _createTextVNode()),
    _createVNode(_component_AuthScopes, {
      mode: _ctx.mode,
      value: _ctx.oauthScopes,
      disabled: !_ctx.isNew,
      "onUpdate:value": _cache[19] || (_cache[19] = ($event: any) => (_ctx.$emit('update:oauthScopes', $event)))
    }, null, 8, ["mode", "value", "disabled"])
  ]))
}