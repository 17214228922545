import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LabeledSelect = _resolveComponent("LabeledSelect")!

  return (_openBlock(), _createBlock(_component_LabeledSelect, _mergeProps(_ctx.labelSelectAttributes, {
    loading: _ctx.$fetchState.pending,
    options: _ctx.allOfType,
    paginate: _ctx.paginateType,
    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:value', $event)))
  }), null, 16, ["loading", "options", "paginate"]))
}