import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PaginatedResourceTable = _resolveComponent("PaginatedResourceTable")!

  return (_openBlock(), _createBlock(_component_PaginatedResourceTable, {
    schema: _ctx.schema,
    fetchSecondaryResources: _ctx.fetchSecondaryResources,
    fetchPageSecondaryResources: _ctx.fetchPageSecondaryResources,
    "use-query-params-for-simple-filtering": _ctx.useQueryParamsForSimpleFiltering,
    "overflow-x": _ctx.overflowX,
    "overflow-y": _ctx.overflowY
  }, null, 8, ["schema", "fetchSecondaryResources", "fetchPageSecondaryResources", "use-query-params-for-simple-filtering", "overflow-x", "overflow-y"]))
}