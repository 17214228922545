import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled", "aria-disabled", "onKeydown"]

import { inject } from 'vue';
import { DropdownContext, defaultContext } from './types';


export default /*@__PURE__*/_defineComponent({
  __name: 'RcDropdownItem',
  props: { disabled: Boolean },
  emits: ['click'],
  setup(__props, { emit: __emit }) {

/**
 * An item for a dropdown menu. Used in conjunction with RcDropdown.
 */
const props = __props;
const emits = __emit;

const { close, dropdownItems } = inject<DropdownContext>('dropdownContext') || defaultContext;

/**
 * Handles keydown events to navigate between dropdown items.
 * @param {KeyboardEvent} e - The keydown event.
 */
const handleKeydown = (e: KeyboardEvent) => {
  const activeItem = document.activeElement;

  const activeIndex = dropdownItems.value.indexOf(activeItem || new HTMLElement());

  if (activeIndex < 0) {
    return;
  }

  const shouldAdvance = e.key === 'ArrowDown';

  const newIndex = findNewIndex(shouldAdvance, activeIndex, dropdownItems.value);

  if (dropdownItems.value[newIndex] instanceof HTMLElement) {
    dropdownItems.value[newIndex].focus();
  }
};

/**
 * Finds the new index for the dropdown item based on the key pressed.
 * @param shouldAdvance - Whether to advance to the next or previous item.
 * @param activeIndex - Current active index.
 * @param itemsArr - Array of dropdown items.
 * @returns The new index.
 */
const findNewIndex = (shouldAdvance: boolean, activeIndex: number, itemsArr: Element[]) => {
  const newIndex = shouldAdvance ? activeIndex + 1 : activeIndex - 1;

  if (newIndex > itemsArr.length - 1) {
    return 0;
  }

  if (newIndex < 0) {
    return itemsArr.length - 1;
  }

  return newIndex;
};

const handleClick = (e: MouseEvent) => {
  if (props.disabled) {
    return;
  }

  emits('click', e);
  close();
};

/**
 * Handles keydown events to activate the dropdown item.
 * @param e - The keydown event.
 */
const handleActivate = (e: KeyboardEvent) => {
  if (e?.target instanceof HTMLElement) {
    e?.target?.click();
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    ref: "dropdownMenuItem",
    "dropdown-menu-item": "",
    tabindex: "-1",
    role: "menuitem",
    disabled: __props.disabled || null,
    "aria-disabled": __props.disabled || false,
    onClick: _withModifiers(handleClick, ["stop"]),
    onKeydown: [
      _withKeys(handleActivate, ["enter","space"]),
      _withKeys(_withModifiers(handleKeydown, ["stop"]), ["up","down"])
    ]
  }, [
    _renderSlot(_ctx.$slots, "before"),
    _cache[0] || (_cache[0] = _createTextVNode()),
    _renderSlot(_ctx.$slots, "default")
  ], 40, _hoisted_1))
}
}

})