import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { computed, ref } from 'vue';
import { ButtonRoleProps, ButtonSizeProps } from './types';


export default /*@__PURE__*/_defineComponent({
  __name: 'RcButton',
  props: {
    primary: { type: Boolean },
    secondary: { type: Boolean },
    tertiary: { type: Boolean },
    link: { type: Boolean },
    ghost: { type: Boolean },
    small: { type: Boolean }
  },
  setup(__props: any, { expose: __expose }) {

/**
 * A button element used for performing actions, such as submitting forms or
 * opening dialogs.
 *
 * Example:
 *
 * <rc-button primary @click="doAction">Perform an Action</rc-button>
 */
const buttonRoles: { role: keyof ButtonRoleProps, className: string }[] = [
  { role: 'primary', className: 'role-primary' },
  { role: 'secondary', className: 'role-secondary' },
  { role: 'tertiary', className: 'role-tertiary' },
  { role: 'link', className: 'role-link' },
  { role: 'ghost', className: 'role-ghost' },
];

const buttonSizes: { size: keyof ButtonSizeProps, className: string }[] = [
  { size: 'small', className: 'btn-sm' },
];

const props = __props;

const buttonClass = computed(() => {
  const activeRole = buttonRoles.find(({ role }) => props[role]);
  const isButtonSmall = buttonSizes.some(({ size }) => props[size]);

  return {
    btn: true,

    [activeRole?.className || 'role-primary']: true,

    'btn-sm': isButtonSmall,
  };
});

const RcFocusTarget = ref<HTMLElement | null>(null);

const focus = () => {
  RcFocusTarget?.value?.focus();
};

__expose({ focus });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    ref_key: "RcFocusTarget",
    ref: RcFocusTarget,
    role: "button",
    class: _normalizeClass({ ...buttonClass.value, ...(_ctx.$attrs.class || { }) })
  }, [
    _renderSlot(_ctx.$slots, "before"),
    _cache[0] || (_cache[0] = _createTextVNode()),
    _renderSlot(_ctx.$slots, "default"),
    _cache[1] || (_cache[1] = _createTextVNode()),
    _renderSlot(_ctx.$slots, "after")
  ], 2))
}
}

})