// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../node_modules/vue-loader/dist/stylePostLoader.js!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scope-select[data-v-024434a4]{min-width:33%;width:unset}.expiry[data-v-024434a4]{display:flex}.expiry[data-v-024434a4]>*{flex:0 0 200px;margin-right:10px}.buttons[data-v-024434a4]{display:grid;grid-template-areas:"left right";grid-template-columns:"min-content auto"}.buttons .right[data-v-024434a4]{grid-area:right;text-align:right}.buttons .right .btn[data-v-024434a4],.buttons .right button[data-v-024434a4]{margin:0 0 0 1.75%}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
