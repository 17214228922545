// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../node_modules/vue-loader/dist/stylePostLoader.js!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.prompt-update.card-container[data-v-12d144ed]{box-shadow:none}.prompt-update[data-v-12d144ed]  .card-actions{display:flex;justify-content:center}.waiting[data-v-12d144ed]{text-align:center;font-size:14px;margin:10px 0}.waiting p[data-v-12d144ed]{line-height:20px}.restarting-icon[data-v-12d144ed]{display:flex;justify-content:center;margin-top:10px}.restarting-icon>I[data-v-12d144ed]{font-size:24px}.feature-name[data-v-12d144ed]{align-items:center;display:flex}.feature-name>i[data-v-12d144ed]{margin-left:10px}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
