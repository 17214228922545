import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row mb-10 align-center" }
const _hoisted_2 = { class: "col span-6" }
const _hoisted_3 = { class: "col span-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LabeledSelect = _resolveComponent("LabeledSelect")!
  const _component_LabeledInput = _resolveComponent("LabeledInput")!
  const _component_Checkbox = _resolveComponent("Checkbox")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.loadingClusters || _ctx.clusterOptions.length)
          ? (_openBlock(), _createBlock(_component_LabeledSelect, {
              key: 0,
              loading: _ctx.loadingClusters,
              value: _ctx.clusterName,
              mode: _ctx.mode,
              label: _ctx.t('gke.import.cluster.label'),
              options: _ctx.clusterOptions,
              rules: _ctx.rules.importName,
              onSelecting: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:clusterName', $event)))
            }, null, 8, ["loading", "value", "mode", "label", "options", "rules"]))
          : (_openBlock(), _createBlock(_component_LabeledInput, {
              key: 1,
              value: _ctx.clusterName,
              mode: _ctx.mode,
              label: _ctx.t('gke.import.cluster.label'),
              rules: _ctx.rules.importName,
              "data-testid": "gke-import-cluster-name-text",
              onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:clusterName', $event.target.value)))
            }, null, 8, ["value", "mode", "label", "rules"]))
      ]),
      _cache[3] || (_cache[3] = _createTextVNode()),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Checkbox, {
          value: _ctx.enableNetworkPolicy,
          mode: _ctx.mode,
          label: _ctx.t('gke.enableNetworkPolicy.label'),
          "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:enableNetworkPolicy', $event)))
        }, null, 8, ["value", "mode", "label"])
      ])
    ])
  ]))
}