import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, withKeys as _withKeys, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { inject, onMounted, useTemplateRef } from 'vue';
import { RcButton, RcButtonType } from '@components/RcButton';
import { DropdownContext, defaultContext } from './types';


export default /*@__PURE__*/_defineComponent({
  __name: 'RcDropdownTrigger',
  setup(__props, { expose: __expose }) {

/**
 * A button that opens a menu. Used in conjunction with `RcDropdown.vue`.
 */
const {
  showMenu,
  registerTrigger,
  isMenuOpen,
  handleKeydown,
} = inject<DropdownContext>('dropdownContext') || defaultContext;

const dropdownTrigger = useTemplateRef<RcButtonType>('dropdownTrigger');

onMounted(() => {
  registerTrigger(dropdownTrigger.value);
});

const focus = () => {
  dropdownTrigger?.value?.focus();
};

__expose({ focus });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(RcButton), {
    ref_key: "dropdownTrigger",
    ref: dropdownTrigger,
    role: "button",
    "aria-haspopup": "menu",
    "aria-expanded": _unref(isMenuOpen),
    onKeydown: _withKeys(_unref(handleKeydown), ["enter","space"]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(showMenu)(true)))
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["aria-expanded", "onKeydown"]))
}
}

})