// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.right-align[data-v-4f650ba7]{float:right}.gauges[data-v-4f650ba7]{display:flex;justify-content:space-around}.gauges[data-v-4f650ba7]>*{flex:1;margin-right:1.75%}.gauges__pods[data-v-4f650ba7]{flex-wrap:wrap;justify-content:left}.gauges__pods .count-gauge[data-v-4f650ba7]{width:23%;margin-bottom:10px;flex:initial}.caption[data-v-4f650ba7]{margin-bottom:.5em}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
