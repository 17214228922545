// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ssh-known-hosts-dialog[data-v-3b53a9bc]{padding:15px}.ssh-known-hosts-dialog h4[data-v-3b53a9bc]{font-weight:700;margin-bottom:20px}.ssh-known-hosts-dialog .dialog-panel[data-v-3b53a9bc]{display:flex;flex-direction:column;min-height:100px}.ssh-known-hosts-dialog .dialog-panel[data-v-3b53a9bc]  .code-mirror{display:flex;flex-direction:column;resize:none}.ssh-known-hosts-dialog .dialog-panel[data-v-3b53a9bc]  .code-mirror .codemirror-container{border:1px solid var(--border)}.ssh-known-hosts-dialog .dialog-panel[data-v-3b53a9bc]  .code-mirror .CodeMirror,.ssh-known-hosts-dialog .dialog-panel[data-v-3b53a9bc]  .code-mirror .CodeMirror-gutters{min-height:400px;max-height:400px;background-color:var(--yaml-editor-bg)}.ssh-known-hosts-dialog .dialog-panel[data-v-3b53a9bc]  .code-mirror .CodeMirror-gutters{width:25px}.ssh-known-hosts-dialog .dialog-panel[data-v-3b53a9bc]  .code-mirror .CodeMirror-linenumber{padding-left:0}.ssh-known-hosts-dialog .dialog-actions[data-v-3b53a9bc]{display:flex;justify-content:space-between}.ssh-known-hosts-dialog .dialog-actions .action-pannel[data-v-3b53a9bc]{margin-top:10px}.ssh-known-hosts-dialog .dialog-actions .form-actions[data-v-3b53a9bc]{display:flex;justify-content:flex-end}.ssh-known-hosts-dialog .dialog-actions .form-actions[data-v-3b53a9bc]>:not(:last-child){margin-right:10px}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
