// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../../../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[dropdown-menu-item][data-v-0b8ae0e4]{display:flex;gap:8px;align-items:center;padding:9px 8px;margin:0 9px;border-radius:4px}[dropdown-menu-item][data-v-0b8ae0e4]:hover{cursor:pointer;background-color:var(--dropdown-hover-bg)}[dropdown-menu-item][data-v-0b8ae0e4]:focus,[dropdown-menu-item][data-v-0b8ae0e4]:focus-visible{outline:2px solid var(--primary-keyboard-focus);outline-offset:0}[dropdown-menu-item][disabled][data-v-0b8ae0e4]{color:var(--disabled-text)}[dropdown-menu-item][disabled][data-v-0b8ae0e4]:hover{cursor:not-allowed}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
