// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.credential-region{display:flex}.credential-region .region{flex-basis:50%;flex-grow:0;margin:0 1.75% 0 0}.credential-region.showing-form{flex-direction:column;flex-grow:1}.credential-region.showing-form>.region{margin:0}.credential-region.showing-form>.select-credential-container{display:flex;flex-direction:column;flex-grow:1}.credential-region>.select-credential-container{flex-basis:50%}.credential-region>.select-credential-container>.select-credential{flex-grow:1}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
