import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row mb-10" }
const _hoisted_2 = { class: "col span-6" }
const _hoisted_3 = { class: "col span-6" }
const _hoisted_4 = { class: "row mb-10" }
const _hoisted_5 = { class: "col span-4" }
const _hoisted_6 = { class: "col span-4" }
const _hoisted_7 = { class: "col span-4" }
const _hoisted_8 = { class: "row mb-10" }
const _hoisted_9 = { class: "col span-6 mt-20" }
const _hoisted_10 = { class: "col span-6 mt-20" }
const _hoisted_11 = { class: "row mb-10" }
const _hoisted_12 = { class: "col span-4 upgrade-version" }
const _hoisted_13 = { class: "col span-4" }
const _hoisted_14 = { class: "col span-4" }
const _hoisted_15 = { class: "row mb-10" }
const _hoisted_16 = { class: "col span-4" }
const _hoisted_17 = { class: "col span-4" }
const _hoisted_18 = { class: "col span-4" }
const _hoisted_19 = { class: "row mb-10" }
const _hoisted_20 = { class: "col span-4" }
const _hoisted_21 = { class: "col span-4" }
const _hoisted_22 = {
  key: 3,
  class: "row mb-10"
}
const _hoisted_23 = { class: "col span-6" }
const _hoisted_24 = { class: "row mb-15" }
const _hoisted_25 = { class: "col span-6 user-data" }
const _hoisted_26 = { class: "col span-6" }
const _hoisted_27 = { row: "mb-10" }
const _hoisted_28 = { class: "col span-12 mt-20" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LabeledInput = _resolveComponent("LabeledInput")!
  const _component_LabeledSelect = _resolveComponent("LabeledSelect")!
  const _component_Banner = _resolveComponent("Banner")!
  const _component_KeyValue = _resolveComponent("KeyValue")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_UnitInput = _resolveComponent("UnitInput")!
  const _component_FileSelector = _resolveComponent("FileSelector")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("h3", null, _toDisplayString(_ctx.t('eks.nodeGroups.groupDetails')), 1),
    _cache[31] || (_cache[31] = _createTextVNode()),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_LabeledInput, {
          value: _ctx.nodegroupName,
          "label-key": "eks.nodeGroups.name.label",
          mode: _ctx.mode,
          disabled: !_ctx.poolIsUnprovisioned,
          rules: _ctx.rules.nodegroupName,
          "data-testid": "eks-nodegroup-name",
          required: "",
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:nodegroupName', $event)))
        }, null, 8, ["value", "mode", "disabled", "rules"])
      ]),
      _cache[20] || (_cache[20] = _createTextVNode()),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_LabeledSelect, {
          value: _ctx.displayNodeRole,
          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.displayNodeRole) = $event)),
          mode: _ctx.mode,
          "label-key": "eks.nodeGroups.nodeRole.label",
          options: [_ctx.defaultNodeRoleOption, ..._ctx.ec2Roles],
          "option-label": "RoleName",
          "option-key": "Arn",
          disabled: !_ctx.poolIsUnprovisioned,
          loading: _ctx.loadingRoles
        }, null, 8, ["value", "mode", "options", "disabled", "loading"])
      ])
    ]),
    _cache[32] || (_cache[32] = _createTextVNode()),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_LabeledInput, {
          type: "number",
          value: _ctx.desiredSize,
          "label-key": "eks.nodeGroups.desiredSize.label",
          mode: _ctx.mode,
          rules: _ctx.rules.desiredSize,
          "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:desiredSize', parseInt($event))))
        }, null, 8, ["value", "mode", "rules"])
      ]),
      _cache[21] || (_cache[21] = _createTextVNode()),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_LabeledInput, {
          type: "number",
          value: _ctx.minSize,
          "label-key": "eks.nodeGroups.minSize.label",
          mode: _ctx.mode,
          rules: _ctx.rules.minSize,
          "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('update:minSize', parseInt($event))))
        }, null, 8, ["value", "mode", "rules"])
      ]),
      _cache[22] || (_cache[22] = _createTextVNode()),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_LabeledInput, {
          type: "number",
          value: _ctx.maxSize,
          "label-key": "eks.nodeGroups.maxSize.label",
          mode: _ctx.mode,
          rules: _ctx.rules.maxSize,
          "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('update:maxSize', parseInt($event))))
        }, null, 8, ["value", "mode", "rules"])
      ])
    ]),
    _cache[33] || (_cache[33] = _createTextVNode()),
    (!!_ctx.minMaxDesiredErrors)
      ? (_openBlock(), _createBlock(_component_Banner, {
          key: 0,
          color: "error",
          label: _ctx.minMaxDesiredErrors
        }, null, 8, ["label"]))
      : _createCommentVNode("", true),
    _cache[34] || (_cache[34] = _createTextVNode()),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_KeyValue, {
          mode: _ctx.mode,
          title: _ctx.t('eks.nodeGroups.groupLabels.label'),
          "read-allowed": false,
          value: _ctx.labels,
          "as-map": true,
          "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('update:labels', $event)))
        }, {
          title: _withCtx(() => [
            _createElementVNode("h4", null, _toDisplayString(_ctx.t('eks.nodeGroups.groupLabels.label')), 1)
          ]),
          _: 1
        }, 8, ["mode", "title", "value"])
      ]),
      _cache[23] || (_cache[23] = _createTextVNode()),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_KeyValue, {
          mode: _ctx.mode,
          title: _ctx.t('eks.nodeGroups.groupTags.label'),
          "read-allowed": false,
          "as-map": true,
          value: _ctx.tags,
          "data-testid": "eks-resource-tags-input",
          "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('update:tags', $event)))
        }, {
          title: _withCtx(() => [
            _createElementVNode("h4", null, _toDisplayString(_ctx.t('eks.nodeGroups.groupTags.label')), 1)
          ]),
          _: 1
        }, 8, ["mode", "title", "value"])
      ])
    ]),
    _cache[35] || (_cache[35] = _createTextVNode()),
    _cache[36] || (_cache[36] = _createElementVNode("hr", { class: "mb-20" }, null, -1)),
    _cache[37] || (_cache[37] = _createTextVNode()),
    _createElementVNode("h3", null, _toDisplayString(_ctx.t('eks.nodeGroups.templateDetails')), 1),
    _cache[38] || (_cache[38] = _createTextVNode()),
    (_ctx.clusterWillUpgrade && !_ctx.poolIsUnprovisioned)
      ? (_openBlock(), _createBlock(_component_Banner, {
          key: 1,
          color: "info",
          "label-key": "eks.nodeGroups.kubernetesVersion.clusterWillUpgrade",
          "data-testid": "eks-version-upgrade-banner"
        }))
      : _createCommentVNode("", true),
    _cache[39] || (_cache[39] = _createTextVNode()),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("div", _hoisted_12, [
        (!_ctx.nodeCanUpgrade)
          ? (_openBlock(), _createBlock(_component_LabeledInput, {
              key: 0,
              "label-key": "eks.nodeGroups.kubernetesVersion.label",
              disabled: true,
              value: _ctx.version,
              "data-testid": "eks-version-display"
            }, null, 8, ["value"]))
          : (_openBlock(), _createBlock(_component_Checkbox, {
              key: 1,
              value: _ctx.willUpgrade,
              "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.willUpgrade) = $event)),
              label: _ctx.t('eks.nodeGroups.kubernetesVersion.upgrade', {from: _ctx.originalNodeVersion, to: _ctx.clusterVersion}),
              "data-testid": "eks-version-upgrade-checkbox",
              disabled: _ctx.isView
            }, null, 8, ["value", "label", "disabled"]))
      ]),
      _cache[24] || (_cache[24] = _createTextVNode()),
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_LabeledSelect, {
          value: _ctx.selectedLaunchTemplate,
          "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.selectedLaunchTemplate) = $event)),
          mode: _ctx.mode,
          "label-key": "eks.nodeGroups.launchTemplate.label",
          options: _ctx.launchTemplateOptions,
          "option-label": "LaunchTemplateName",
          "option-key": "LaunchTemplateId",
          disabled: !_ctx.poolIsUnprovisioned,
          loading: _ctx.loadingLaunchTemplates,
          "data-testid": "eks-launch-template-dropdown"
        }, null, 8, ["value", "mode", "options", "disabled", "loading"])
      ]),
      _cache[25] || (_cache[25] = _createTextVNode()),
      _createElementVNode("div", _hoisted_14, [
        (_ctx.hasUserLaunchTemplate)
          ? (_openBlock(), _createBlock(_component_LabeledSelect, {
              key: 0,
              value: _ctx.launchTemplate.version,
              mode: _ctx.mode,
              "label-key": "eks.nodeGroups.launchTemplate.version",
              options: _ctx.launchTemplateVersionOptions,
              "data-testid": "eks-launch-template-version-dropdown",
              "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => (_ctx.$emit('update:launchTemplate', {..._ctx.launchTemplate, version: $event})))
            }, null, 8, ["value", "mode", "options"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _cache[40] || (_cache[40] = _createTextVNode()),
    _createVNode(_component_Banner, {
      color: "info",
      "label-key": "eks.nodeGroups.imageId.tooltip"
    }),
    _cache[41] || (_cache[41] = _createTextVNode()),
    _createElementVNode("div", _hoisted_15, [
      _createElementVNode("div", _hoisted_16, [
        _createVNode(_component_LabeledInput, {
          "label-key": "eks.nodeGroups.imageId.label",
          mode: _ctx.mode,
          value: _ctx.imageId,
          disabled: _ctx.hasUserLaunchTemplate,
          "data-testid": "eks-image-id-input",
          "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => (_ctx.$emit('update:imageId', $event)))
        }, null, 8, ["mode", "value", "disabled"])
      ]),
      _cache[26] || (_cache[26] = _createTextVNode()),
      _createElementVNode("div", _hoisted_17, [
        _createVNode(_component_LabeledSelect, {
          required: !_ctx.requestSpotInstances && !_ctx.templateValue('instanceType'),
          mode: _ctx.mode,
          "label-key": "eks.nodeGroups.instanceType.label",
          options: _ctx.instanceTypeOptions,
          loading: _ctx.loadingInstanceTypes,
          value: _ctx.instanceType,
          disabled: !!_ctx.templateValue('instanceType') || _ctx.requestSpotInstances,
          tooltip: (_ctx.requestSpotInstances && !_ctx.templateValue('instanceType')) ? _ctx.t('eks.nodeGroups.instanceType.tooltip'): '',
          rules: !_ctx.requestSpotInstances ? _ctx.rules.instanceType : [],
          "data-testid": "eks-instance-type-dropdown",
          "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => (_ctx.$emit('update:instanceType', $event)))
        }, null, 8, ["required", "mode", "options", "loading", "value", "disabled", "tooltip", "rules"])
      ]),
      _cache[27] || (_cache[27] = _createTextVNode()),
      _createElementVNode("div", _hoisted_18, [
        _createVNode(_component_UnitInput, {
          required: !_ctx.templateValue('diskSize'),
          "label-key": "eks.nodeGroups.diskSize.label",
          mode: _ctx.mode,
          value: _ctx.diskSize,
          suffix: "GB",
          loading: _ctx.loadingSelectedVersion,
          disabled: !!_ctx.templateValue('diskSize') || _ctx.loadingSelectedVersion,
          rules: _ctx.rules.diskSize,
          "data-testid": "eks-disksize-input",
          "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => (_ctx.$emit('update:diskSize', $event)))
        }, null, 8, ["required", "mode", "value", "loading", "disabled", "rules"])
      ])
    ]),
    _cache[42] || (_cache[42] = _createTextVNode()),
    (_ctx.requestSpotInstances && _ctx.hasUserLaunchTemplate)
      ? (_openBlock(), _createBlock(_component_Banner, {
          key: 2,
          color: "warning",
          label: _ctx.t('eks.nodeGroups.requestSpotInstances.warning'),
          "data-testid": "eks-spot-instance-banner"
        }, null, 8, ["label"]))
      : _createCommentVNode("", true),
    _cache[43] || (_cache[43] = _createTextVNode()),
    _createElementVNode("div", _hoisted_19, [
      _createElementVNode("div", _hoisted_20, [
        _createVNode(_component_Checkbox, {
          mode: _ctx.mode,
          "label-key": "eks.nodeGroups.gpu.label",
          value: _ctx.gpu,
          disabled: !!_ctx.templateValue('imageId') || _ctx.hasRancherLaunchTemplate,
          tooltip: _ctx.templateValue('imageId') ? _ctx.t('eks.nodeGroups.gpu.tooltip') : '',
          "data-testid": "eks-gpu-input",
          "onUpdate:value": _cache[13] || (_cache[13] = ($event: any) => (_ctx.$emit('update:gpu', $event)))
        }, null, 8, ["mode", "value", "disabled", "tooltip"])
      ]),
      _cache[28] || (_cache[28] = _createTextVNode()),
      _createElementVNode("div", _hoisted_21, [
        _createVNode(_component_Checkbox, {
          value: _ctx.requestSpotInstances,
          mode: _ctx.mode,
          "label-key": "eks.nodeGroups.requestSpotInstances.label",
          disabled: _ctx.hasRancherLaunchTemplate,
          "onUpdate:value": _cache[14] || (_cache[14] = ($event: any) => (_ctx.$emit('update:requestSpotInstances', $event)))
        }, null, 8, ["value", "mode", "disabled"])
      ])
    ]),
    _cache[44] || (_cache[44] = _createTextVNode()),
    (_ctx.requestSpotInstances && !_ctx.templateValue('instanceType'))
      ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
          _createElementVNode("div", _hoisted_23, [
            _createVNode(_component_LabeledSelect, {
              mode: _ctx.mode,
              value: _ctx.spotInstanceTypes,
              "label-key": "eks.nodeGroups.spotInstanceTypes.label",
              options: _ctx.spotInstanceTypeOptions,
              multiple: true,
              loading: _ctx.loadingSelectedVersion || _ctx.loadingInstanceTypes,
              "data-testid": "eks-spot-instance-type-dropdown",
              "onUpdate:value": _cache[15] || (_cache[15] = ($event: any) => (_ctx.$emit('update:spotInstanceTypes', $event)))
            }, null, 8, ["mode", "value", "options", "loading"])
          ])
        ]))
      : _createCommentVNode("", true),
    _cache[45] || (_cache[45] = _createTextVNode()),
    _createElementVNode("div", _hoisted_24, [
      _createElementVNode("div", _hoisted_25, [
        _createVNode(_component_LabeledInput, {
          "label-key": "eks.nodeGroups.userData.label",
          mode: _ctx.mode,
          type: "multiline",
          value: _ctx.userData,
          disabled: _ctx.hasUserLaunchTemplate,
          placeholder: _ctx.userDataPlaceholder,
          "sub-label": _ctx.t('eks.nodeGroups.userData.tooltip', {}, true),
          "onUpdate:value": _cache[16] || (_cache[16] = ($event: any) => (_ctx.$emit('update:userData', $event)))
        }, null, 8, ["mode", "value", "disabled", "placeholder", "sub-label"]),
        _cache[29] || (_cache[29] = _createTextVNode()),
        _createVNode(_component_FileSelector, {
          mode: _ctx.mode,
          label: _ctx.t('generic.readFromFile'),
          class: "role-tertiary mt-20",
          onSelected: _cache[17] || (_cache[17] = ($event: any) => (_ctx.$emit('update:userData', $event)))
        }, null, 8, ["mode", "label"])
      ]),
      _cache[30] || (_cache[30] = _createTextVNode()),
      _createElementVNode("div", _hoisted_26, [
        _createVNode(_component_LabeledSelect, {
          loading: _ctx.loadingSshKeyPairs,
          value: _ctx.ec2SshKey,
          options: _ctx.sshKeyPairs,
          "label-key": "eks.nodeGroups.ec2SshKey.label",
          mode: _ctx.mode,
          disabled: _ctx.hasUserLaunchTemplate,
          taggable: true,
          searchable: true,
          "data-testid": "eks-nodegroup-ec2-key-select",
          "onUpdate:value": _cache[18] || (_cache[18] = ($event: any) => (_ctx.$emit('update:ec2SshKey', $event)))
        }, null, 8, ["loading", "value", "options", "mode", "disabled"])
      ])
    ]),
    _cache[46] || (_cache[46] = _createTextVNode()),
    _createElementVNode("div", _hoisted_27, [
      _createElementVNode("div", _hoisted_28, [
        _createVNode(_component_KeyValue, {
          mode: _ctx.mode,
          "label-key": "eks.nodeGroups.resourceTags.label",
          value: _ctx.resourceTags,
          disabled: _ctx.hasUserLaunchTemplate,
          "read-allowed": false,
          "as-map": true,
          "onUpdate:value": _cache[19] || (_cache[19] = ($event: any) => (_ctx.$emit('update:resourceTags', $event)))
        }, {
          title: _withCtx(() => [
            _createElementVNode("h4", null, _toDisplayString(_ctx.t('eks.nodeGroups.resourceTags.label')), 1)
          ]),
          _: 1
        }, 8, ["mode", "value", "disabled"])
      ])
    ])
  ]))
}