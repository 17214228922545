// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main[data-v-2551f06e]{overflow:auto}.embedded[data-v-2551f06e]{border-bottom:2px solid var(--primary);border:2px solid var(--primary);padding-left:10px;display:flex;flex-direction:column}.embedded .title-spacer[data-v-2551f06e],.sub-name[data-v-2551f06e]{margin-top:10px}.sub-name[data-v-2551f06e]{background-color:var(--primary);color:var(--primary-text);padding:4px;display:flex}.sub-name[data-v-2551f06e] :first-child{flex:1}.sub-name .sub-type-link[data-v-2551f06e]{color:var(--primary-text);display:flex}.sub-name .sub-name-goto[data-v-2551f06e]{flex:0;font-size:16px;padding-right:2px}.title[data-v-2551f06e]{text-transform:uppercase;margin:10px 0;border-top:1px solid var(--border);border-bottom:1px solid var(--border);padding:6px 0}.field-section[data-v-2551f06e]{align-items:center;display:flex;flex-direction:row;flex-wrap:wrap;margin-top:10px}.field-section .field[data-v-2551f06e]{margin-right:20px}.field-description[data-v-2551f06e]{line-height:1.25;white-space:pre-wrap}.field[data-v-2551f06e]{margin:5px 0;font-weight:700;min-width:100px}.field-type-panel[data-v-2551f06e]{align-items:center;display:flex}.field-type[data-v-2551f06e]{border:1px solid var(--border);padding:1px 5px;border-radius:5px;opacity:.85}.field-link[data-v-2551f06e]{margin-left:10px}.field-link I[data-v-2551f06e]{font-size:12px;width:12px;height:12px}.field-expander[data-v-2551f06e]{align-items:center;display:flex;-webkit-user-select:none;-moz-user-select:none;user-select:none}.field-expander>i[data-v-2551f06e]{font-size:14px;padding-left:8px}.field-expander[data-v-2551f06e]:hover{border-color:var(--link);background-color:var(--link);color:var(--link-text);cursor:pointer}.field-expander[data-v-2551f06e]:focus-visible{outline:2px solid var(--primary-keyboard-focus);outline-offset:2px}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
