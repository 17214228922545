// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../node_modules/vue-loader/dist/stylePostLoader.js!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-row .no-entries[data-v-6e382aec]{text-align:center}.pool-row[data-v-6e382aec]{display:flex;align-items:center;justify-content:space-between}.pool-row.has-description .group-tab[data-v-6e382aec],.pool-row.has-description .group-tab[data-v-6e382aec]:after{height:50px}.pool-row.has-description .group-tab[data-v-6e382aec]:after{right:-20px}.pool-row.has-description .group-tab .description[data-v-6e382aec]{margin-top:-20px}.pool-row .group-header-buttons[data-v-6e382aec]{align-items:center;display:flex}.logs-container[data-v-6e382aec]{height:100%;overflow:auto;padding:5px;background-color:var(--logs-bg);font-family:Menlo,Consolas,monospace;color:var(--logs-text)}.logs-container .closed[data-v-6e382aec]{opacity:.25}.logs-container .time[data-v-6e382aec]{white-space:nowrap;width:auto;padding-right:15px;-webkit-user-select:none;-moz-user-select:none;user-select:none}.logs-container .msg[data-v-6e382aec]{white-space:normal}.logs-container .msg .highlight[data-v-6e382aec]{color:var(--logs-highlight);background-color:var(--logs-highlight-bg)}.snapshots[data-v-6e382aec]  .state-description{font-size:.8em;color:var(--error)}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
