import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "row mb-10" }
const _hoisted_2 = { class: "col span-6" }
const _hoisted_3 = { class: "row mb-10" }
const _hoisted_4 = { class: "col span-6" }
const _hoisted_5 = { class: "row mb-10 mt-20" }
const _hoisted_6 = {
  key: 0,
  class: "col span-6"
}
const _hoisted_7 = { class: "row mb-10" }
const _hoisted_8 = {
  key: 0,
  class: "col span-6"
}
const _hoisted_9 = {
  key: 1,
  class: "col span-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Banner = _resolveComponent("Banner")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_ArrayList = _resolveComponent("ArrayList")!
  const _component_RadioGroup = _resolveComponent("RadioGroup")!
  const _component_LabeledSelect = _resolveComponent("LabeledSelect")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Banner, {
      color: "info",
      "label-key": "eks.publicAccess.tooltip"
    }),
    _cache[8] || (_cache[8] = _createTextVNode()),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Checkbox, {
          value: _ctx.publicAccess,
          mode: _ctx.mode,
          "label-key": "eks.publicAccess.label",
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:publicAccess', $event)))
        }, null, 8, ["value", "mode"]),
        _cache[6] || (_cache[6] = _createTextVNode()),
        _createVNode(_component_Checkbox, {
          value: _ctx.privateAccess,
          mode: _ctx.mode,
          "label-key": "eks.privateAccess.label",
          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:privateAccess', $event)))
        }, null, 8, ["value", "mode"])
      ])
    ]),
    _cache[9] || (_cache[9] = _createTextVNode()),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_ArrayList, {
          value: _ctx.publicAccessSources,
          mode: _ctx.mode,
          disabled: !_ctx.publicAccess,
          "add-allowed": _ctx.publicAccess,
          "add-label": _ctx.t('eks.publicAccessSources.addEndpoint'),
          "data-testid": "eks-public-access-sources",
          "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:publicAccessSources', $event)))
        }, {
          title: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('eks.publicAccessSources.label')), 1)
          ]),
          _: 1
        }, 8, ["value", "mode", "disabled", "add-allowed", "add-label"])
      ])
    ]),
    _cache[10] || (_cache[10] = _createTextVNode()),
    _createElementVNode("div", _hoisted_5, [
      (_ctx.isNew)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_RadioGroup, {
              value: _ctx.chooseSubnet,
              "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.chooseSubnet) = $event)),
              name: "subnet-mode",
              mode: _ctx.mode,
              options: [{label: _ctx.t('eks.subnets.default'), value: false},{label: _ctx.t('eks.subnets.useCustom'), value: true}],
              "label-key": "eks.subnets.title",
              disabled: !_ctx.isNew
            }, null, 8, ["value", "mode", "options", "disabled"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _cache[11] || (_cache[11] = _createTextVNode()),
    _createElementVNode("div", _hoisted_7, [
      (_ctx.chooseSubnet || !_ctx.isNew)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createVNode(_component_LabeledSelect, {
              value: _ctx.displaySubnets,
              "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.displaySubnets) = $event)),
              disabled: !_ctx.isNew,
              mode: _ctx.mode,
              "label-key": "eks.vpcSubnet.label",
              options: _ctx.vpcOptions,
              loading: _ctx.loadingVpcs,
              "option-key": "key",
              multiple: true,
              rules: _ctx.rules && _ctx.rules.subnets,
              "data-testid": "eks-subnets-dropdown"
            }, {
              option: _withCtx((option) => [
                _createElementVNode("span", {
                  class: _normalizeClass({'pl-30': option._isSubnet})
                }, _toDisplayString(option.label), 3)
              ]),
              _: 1
            }, 8, ["value", "disabled", "mode", "options", "loading", "rules"])
          ]))
        : _createCommentVNode("", true),
      _cache[7] || (_cache[7] = _createTextVNode()),
      (_ctx.chooseSubnet)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createVNode(_component_LabeledSelect, {
              mode: _ctx.mode,
              disabled: !_ctx.isNew,
              "label-key": "eks.securityGroups.label",
              tooltip: _ctx.t('eks.securityGroups.tooltip'),
              options: _ctx.securityGroupOptions,
              multiple: true,
              value: _ctx.securityGroups,
              loading: _ctx.loadingSecurityGroups,
              "data-testid": "eks-security-groups-dropdown",
              "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('update:securityGroups', $event)))
            }, null, 8, ["mode", "disabled", "tooltip", "options", "value", "loading"])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}