import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withKeys as _withKeys, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "toggle-container" }
const _hoisted_2 = { class: "switch hand" }
const _hoisted_3 = ["checked", "aria-label"]
const _hoisted_4 = {
  ref: "switchChrome",
  class: "slider round"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createElementVNode("span", {
      class: _normalizeClass(["label no-select hand", { active: !_ctx.state}]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggle(false)))
    }, _toDisplayString(_ctx.offLabel), 3),
    _cache[5] || (_cache[5] = _createTextVNode()),
    _createElementVNode("label", _hoisted_2, [
      _createElementVNode("input", {
        ref: "switchInput",
        type: "checkbox",
        role: "switch",
        checked: _ctx.state,
        "aria-label": _ctx.onLabel,
        onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toggle(null))),
        onKeydown: _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.toggle(null)), ["enter"]))
      }, null, 40, _hoisted_3),
      _cache[4] || (_cache[4] = _createTextVNode()),
      _createElementVNode("span", _hoisted_4, null, 512)
    ]),
    _cache[6] || (_cache[6] = _createTextVNode()),
    _createElementVNode("span", {
      class: _normalizeClass(["label no-select hand", { active: _ctx.state}]),
      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.toggle(true)))
    }, _toDisplayString(_ctx.onLabel), 3)
  ]))
}