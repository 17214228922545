import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "project mb-10" }
const _hoisted_2 = {
  key: 0,
  class: "row mt-10"
}
const _hoisted_3 = { class: "auth-button-container mb-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LabeledInput = _resolveComponent("LabeledInput")!
  const _component_SelectCredential = _resolveComponent("SelectCredential")!
  const _component_AsyncButton = _resolveComponent("AsyncButton")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: _normalizeClass([{'showing-form': !_ctx.credential}, "credential-project"])
    }, [
      _withDirectives(_createElementVNode("div", _hoisted_1, [
        _createVNode(_component_LabeledInput, {
          disabled: _ctx.isAuthenticated,
          value: _ctx.project,
          "label-key": "gke.project.label",
          required: "",
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:project', $event)))
        }, null, 8, ["disabled", "value"])
      ], 512), [
        [_vShow, !!_ctx.credential]
      ]),
      _cache[2] || (_cache[2] = _createTextVNode()),
      _createElementVNode("div", {
        class: _normalizeClass([{'view': _ctx.isView}, "select-credential-container mb-10"])
      }, [
        _createVNode(_component_SelectCredential, {
          value: _ctx.credential,
          "data-testid": "crugke-select-credential",
          mode: (_ctx.isView|| _ctx.isAuthenticated) ? _ctx.VIEW : _ctx.CREATE,
          provider: "gcp",
          "default-on-cancel": true,
          "showing-form": !_ctx.credential,
          class: "select-credential",
          cancel: ()=>_ctx.$emit('cancel-credential'),
          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:credential', $event))),
          onCredentialCreated: _ctx.parseNewCredential
        }, null, 8, ["value", "mode", "showing-form", "cancel", "onCredentialCreated"])
      ], 2)
    ], 2),
    _cache[3] || (_cache[3] = _createTextVNode()),
    (!_ctx.isView && !_ctx.isAuthenticated)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _withDirectives(_createElementVNode("div", _hoisted_3, [
            _createVNode(_component_AsyncButton, {
              disabled: !_ctx.credential || !_ctx.project || _ctx.isAuthenticated,
              type: "button",
              class: "btn",
              mode: "authenticate",
              onClick: _ctx.testProjectId
            }, null, 8, ["disabled", "onClick"])
          ], 512), [
            [_vShow, !!_ctx.credential]
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}