import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "icon-center" }

import { computed } from 'vue';

import { ucFirst } from '@shell/utils/string';


export default /*@__PURE__*/_defineComponent({
  __name: 'TableDataUserIcon',
  props: {
    userState: {},
    isActive: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;

const iconClass = computed(() => {
  const userIcon = `icon-user-${ props.isActive ? 'check' : 'xmark' }`;
  const iconColor = `icon-color-${ props.isActive ? 'green' : 'red' }`;

  return {
    icon:        true,
    'icon-lg':   true,
    [userIcon]:  true,
    [iconColor]: true,
  };
});

return (_ctx: any,_cache: any) => {
  const _directive_clean_tooltip = _resolveDirective("clean-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("i", {
      class: _normalizeClass(iconClass.value)
    }, null, 2), [
      [_directive_clean_tooltip, _unref(ucFirst)(_ctx.userState)]
    ])
  ]))
}
}

})