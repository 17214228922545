import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row mb-10 align-center" }
const _hoisted_2 = { class: "col span-6" }
const _hoisted_3 = { class: "col span-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Banner = _resolveComponent("Banner")!
  const _component_LabeledSelect = _resolveComponent("LabeledSelect")!
  const _component_Checkbox = _resolveComponent("Checkbox")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.rbacEnabled)
      ? (_openBlock(), _createBlock(_component_Banner, {
          key: 0,
          "label-key": "aks.importedRbac",
          color: "warning"
        }))
      : _createCommentVNode("", true),
    _cache[1] || (_cache[1] = _createTextVNode()),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_LabeledSelect, {
          required: "",
          value: _ctx.clusterName,
          mode: _ctx.mode,
          options: _ctx.allClusters,
          "label-key": "aks.clusterToRegister",
          "option-label": "clusterName",
          loading: _ctx.loadingClusters,
          rules: _ctx.rules.clusterName,
          "onUpdate:value": _ctx.setCluster
        }, null, 8, ["value", "mode", "options", "loading", "rules", "onUpdate:value"])
      ]),
      _cache[0] || (_cache[0] = _createTextVNode()),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Checkbox, {
          value: _ctx.enableNetworkPolicy,
          mode: _ctx.mode,
          "label-key": "aks.enableNetworkPolicy.label",
          "onUpdate:value": _ctx.updateNetworkPolicy
        }, null, 8, ["value", "mode", "onUpdate:value"])
      ])
    ])
  ], 64))
}