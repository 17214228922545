import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ResourceTable = _resolveComponent("ResourceTable")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_ResourceTable, _mergeProps(_ctx.$attrs, {
      schema: _ctx.schema,
      rows: _ctx.rows,
      "alt-loading": _ctx.canPaginate && !_ctx.isFirstLoad,
      loading: _ctx.loading,
      groupable: _ctx.groupable,
      headers: _ctx.safeHeaders,
      namespaced: _ctx.namespaced,
      "external-pagination-enabled": _ctx.canPaginate,
      "external-pagination-result": _ctx.paginationResult,
      onPaginationChanged: _ctx.paginationChanged
    }), _createSlots({ _: 2 }, [
      _renderList(_ctx.$slots, (_, slot) => {
        return {
          name: slot,
          fn: _withCtx((scope) => [
            _renderSlot(_ctx.$slots, slot, _normalizeProps(_guardReactiveProps(scope)))
          ])
        }
      })
    ]), 1040, ["schema", "rows", "alt-loading", "loading", "groupable", "headers", "namespaced", "external-pagination-enabled", "external-pagination-result", "onPaginationChanged"])
  ]))
}