// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../node_modules/vue-loader/dist/stylePostLoader.js!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cru-resource-yaml-container .resource-yaml .yaml-editor[data-v-cae1927a]{min-height:100px}.create-resource-container .resource-container[data-v-cae1927a]{display:flex;flex-direction:column}.create-resource-container .subtype-banner .round-image[data-v-cae1927a]{background-color:var(--primary)}.create-resource-container .subtype-banner[data-v-cae1927a]:focus-visible{outline:2px solid var(--primary-keyboard-focus)}.title[data-v-cae1927a]{margin-top:20px}.title.with-description[data-v-cae1927a]{margin-top:0}.subtype-container[data-v-cae1927a]{position:relative;display:flex;height:100%}.subtype-body[data-v-cae1927a]{flex:1;padding:10px}.subtype-logo[data-v-cae1927a]{align-items:center;display:flex;justify-content:center;min-width:100px;min-height:100px;overflow:hidden;background-color:var(--box-bg)}.subtype-logo img[data-v-cae1927a]{width:56px;height:56px;-o-object-fit:contain;object-fit:contain;position:relative;top:2px}form.create-resource-container .cru__footer[data-v-cae1927a]{border-top:var(--header-border-size) solid var(--header-border)}.cru[data-v-cae1927a],.cru__form[data-v-cae1927a]{display:flex;flex-direction:column;flex-grow:1}.cru__content[data-v-cae1927a]{flex-grow:1}.cru__content-wizard[data-v-cae1927a]{display:flex}.cru__footer[data-v-cae1927a]{right:0;bottom:0;margin-left:-20px;margin-right:-20px;margin-bottom:-20px;padding:10px 20px}.cru__errors[data-v-cae1927a],.cru__footer[data-v-cae1927a]{position:sticky;background-color:var(--header-bg)}.cru__errors[data-v-cae1927a]{top:0;z-index:1;margin:10px 0}.description[data-v-cae1927a]{margin-bottom:15px;margin-top:5px}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
