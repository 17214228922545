import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "row mb-10" }
const _hoisted_2 = {
  key: 0,
  class: "col span-4"
}
const _hoisted_3 = { class: "row location-row mb-10" }
const _hoisted_4 = { class: "col span-4" }
const _hoisted_5 = {
  key: 0,
  class: "col span-2 extra-zones",
  "data-testid": "gke-extra-zones-container"
}
const _hoisted_6 = { class: "text-muted" }
const _hoisted_7 = {
  key: 0,
  class: "text-muted"
}
const _hoisted_8 = { class: "col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LabeledInput = _resolveComponent("LabeledInput")!
  const _component_LabeledSelect = _resolveComponent("LabeledSelect")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_RadioGroup = _resolveComponent("RadioGroup")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass({col: true, 'span-4': !_ctx.isImport, 'span-6': _ctx.isImport})
      }, [
        _createVNode(_component_LabeledInput, {
          value: _ctx.clusterName,
          mode: _ctx.mode,
          "label-key": "generic.name",
          required: "",
          rules: _ctx.rules.clusterName,
          "data-testid": "gke-cluster-name",
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:clusterName', $event)))
        }, null, 8, ["value", "mode", "rules"])
      ], 2),
      _cache[4] || (_cache[4] = _createTextVNode()),
      _createElementVNode("div", {
        class: _normalizeClass({col: true, 'span-4': !_ctx.isImport, 'span-6': _ctx.isImport})
      }, [
        _createVNode(_component_LabeledInput, {
          value: _ctx.clusterDescription,
          mode: _ctx.mode,
          "label-key": "nameNsDescription.description.label",
          placeholder: _ctx.t('nameNsDescription.description.placeholder'),
          "data-testid": "gke-cluster-description",
          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:clusterDescription', $event)))
        }, null, 8, ["value", "mode", "placeholder"])
      ], 2),
      _cache[5] || (_cache[5] = _createTextVNode()),
      (!_ctx.isImport)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_LabeledSelect, {
              options: _ctx.versionOptions,
              "label-key": "gke.version.label",
              value: _ctx.kubernetesVersion,
              tooltip: _ctx.isCreate? '' :_ctx.t('gke.version.tooltip'),
              loading: _ctx.loadingVersions,
              "data-testid": "gke-version-select",
              mode: _ctx.mode,
              onSelecting: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:kubernetesVersion', $event)))
            }, null, 8, ["options", "value", "tooltip", "loading", "mode"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _cache[10] || (_cache[10] = _createTextVNode()),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        (_ctx.useRegion)
          ? (_openBlock(), _createBlock(_component_LabeledSelect, {
              key: 0,
              "label-key": "gke.location.region",
              mode: _ctx.mode,
              options: _ctx.regions,
              value: _ctx.region,
              disabled: !_ctx.isNewOrUnprovisioned,
              loading: _ctx.loadingZones,
              onSelecting: _ctx.setRegion
            }, null, 8, ["mode", "options", "value", "disabled", "loading", "onSelecting"]))
          : (_openBlock(), _createBlock(_component_LabeledSelect, {
              key: 1,
              "label-key": "gke.location.zone",
              mode: _ctx.mode,
              options: _ctx.zones,
              "option-key": "name",
              "option-label": "name",
              value: _ctx.zone,
              disabled: !_ctx.isNewOrUnprovisioned,
              loading: _ctx.loadingZones,
              "data-testid": "gke-zone-select",
              onSelecting: _ctx.setZone
            }, null, 8, ["mode", "options", "value", "disabled", "loading", "onSelecting"]))
      ]),
      _cache[8] || (_cache[8] = _createTextVNode()),
      (!_ctx.loadingZones && !_ctx.isImport)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.t('gke.location.extraZones')), 1),
            _cache[6] || (_cache[6] = _createTextVNode()),
            (_ctx.isView && !_ctx.locations.length)
              ? (_openBlock(), _createElementBlock("span", _hoisted_7, "—"))
              : _createCommentVNode("", true),
            _cache[7] || (_cache[7] = _createTextVNode()),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.extraZoneOptions, (zoneOpt, i) => {
              return (_openBlock(), _createBlock(_component_Checkbox, {
                key: i,
                label: zoneOpt.name,
                value: _ctx.locations.includes(zoneOpt.name),
                "data-testid": `gke-extra-zones-${zoneOpt.name}`,
                disabled: _ctx.isView,
                class: "extra-zone-checkbox",
                "onUpdate:value": e=>_ctx.setExtraZone(e, zoneOpt.name)
              }, null, 8, ["label", "value", "data-testid", "disabled", "onUpdate:value"]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      _cache[9] || (_cache[9] = _createTextVNode()),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_RadioGroup, {
          value: _ctx.useRegion,
          "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.useRegion) = $event)),
          mode: _ctx.mode,
          options: _ctx.zoneRadioOptions,
          name: "regionmode",
          disabled: !_ctx.isNewOrUnprovisioned,
          "data-testid": "gke-location-mode-radio"
        }, null, 8, ["value", "mode", "options", "disabled"])
      ])
    ])
  ]))
}