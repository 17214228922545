import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "region mb-10" }
const _hoisted_2 = { class: "select-credential-container mb-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LabeledSelect = _resolveComponent("LabeledSelect")!
  const _component_SelectCredential = _resolveComponent("SelectCredential")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{'showing-form': !_ctx.credential}, "credential-region"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_LabeledSelect, {
        disabled: _ctx.mode!=='create',
        value: _ctx.region,
        "label-key": "eks.region.label",
        options: _ctx.regionOptions,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update-region', $event)))
      }, null, 8, ["disabled", "value", "options"])
    ]),
    _cache[2] || (_cache[2] = _createTextVNode()),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_SelectCredential, {
        value: _ctx.credential,
        "data-testid": "crueks-select-credential",
        mode: _ctx.mode === _ctx.VIEW ? _ctx.VIEW : _ctx.CREATE,
        provider: "aws",
        "default-on-cancel": true,
        "showing-form": !_ctx.credential,
        class: "select-credential",
        cancel: ()=>_ctx.$emit('cancel-credential'),
        "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update-credential', $event)))
      }, null, 8, ["value", "mode", "showing-form", "cancel"])
    ])
  ], 2))
}