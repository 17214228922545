import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

import { isAlternate } from '@shell/utils/platform';
import { RcDropdownMenu } from '@components/RcDropdown';
import { ButtonRoleProps, ButtonSizeProps } from '@components/RcButton/types';
import { DropdownOption } from '@components/RcDropdown/types';

type RcDropdownMenuComponentProps = {
  buttonRole?: keyof ButtonRoleProps;
  buttonSize?: keyof ButtonSizeProps;
  buttonAriaLabel?: string;
  dropdownAriaLabel?: string;
  dataTestid?: string;
  resource: Object;
  customActions?: DropdownOption[];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ActionMenuShell',
  props: {
    buttonRole: {},
    buttonSize: {},
    buttonAriaLabel: {},
    dropdownAriaLabel: {},
    dataTestid: {},
    resource: {},
    customActions: {}
  },
  setup(__props: any, { emit: __emit }) {

const store = useStore();

const props = __props;

const openChanged = (event: boolean) => {
  if (event) {
    store.dispatch('action-menu/setResource', props.resource);
  }
};

const emit = __emit;
const route = useRoute();

const execute = (action: any, event: MouseEvent, args?: any) => {
  if (action.disabled) {
    return;
  }

  // this will come from extensions...
  if (action.invoke) {
    const fn = action.invoke;

    if (fn && action.enabled) {
      const resources = store.getters['action-menu/resources'];
      const opts = {
        event,
        action,
        isAlt: isAlternate(event)
      };

      if (resources.length === 1) {
        fn.apply(this, [opts, resources]);
      }
    }
  } else if (props.customActions) {
    // If the state of this component is controlled
    // by props instead of Vuex, we assume you wouldn't want
    // the mutation to have a dependency on Vuex either.
    // So in that case we use events to execute actions instead.
    // If an action list item is clicked, this
    // component emits that event, then we assume the parent
    // component will execute the action.
    emit(
      action.action,
      {
        action,
        event,
        ...args,
        route,
      }
    );
  } else {
    // If the state of this component is controlled
    // by Vuex, mutate the store when an action is clicked.
    const opts = { alt: isAlternate(event) };

    store.dispatch('action-menu/execute', {
      action, args, opts
    });
  }
};

const options = computed(() => store.getters['action-menu/optionsArray']);

const menuOptions = () => {
  if (props.customActions && props.customActions.length > 0) {
    return props.customActions;
  }

  return options.value;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(RcDropdownMenu), {
    "button-role": _ctx.buttonRole || 'link',
    "button-size": _ctx.buttonSize || 'small',
    "button-aria-label": _ctx.buttonAriaLabel,
    "dropdown-aria-label": _ctx.dropdownAriaLabel,
    options: menuOptions(),
    "data-testid": _ctx.dataTestid,
    "onUpdate:open": openChanged,
    onSelect: _cache[0] || (_cache[0] = (e, option) => execute(option, e))
  }, null, 8, ["button-role", "button-size", "button-aria-label", "dropdown-aria-label", "options", "data-testid"]))
}
}

})