// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../../../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button.role-link.focused[data-v-0d798981],button.role-link[data-v-0d798981]:focus{outline:2px solid var(--primary-keyboard-focus);outline-offset:-2px}button.role-link[data-v-0d798981]:hover{background-color:var(--accent-btn);box-shadow:none}button.role-ghost[data-v-0d798981]{padding:0;background-color:transparent}button.role-ghost.focused[data-v-0d798981],button.role-ghost[data-v-0d798981]:focus{outline:2px solid var(--primary-keyboard-focus);outline-offset:0}button.role-ghost[data-v-0d798981]:focus-visible{outline:2px solid var(--primary-keyboard-focus);outline-offset:0}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
